<div class="ui-g-12">
  <p-tabView orientation="left" styleClass="stepMenu">
    <p-tabPanel header="Waybills">
      <div class="ui-g-12">
        <digi-header [roadManifest]="roadManifest"
                     (onUpdateRoadManifest)="updateRoadManifest($event)"
                     (onCreateNew)="createNewWaybill()"
        ></digi-header>
        <div class="ui-g">
          <div class="ui-g-4 ui-md-4">
            <div class="ui-g-12 ui-md-12"></div>
            <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
    <span class="ui-inputgroup">
      <span class="ui-float-label ui-fluid">
            <p-autoComplete id="boes"
                            field="transportDocNo"
                            [suggestions]="filteredBoes"
                            (completeMethod)="searchBoE($event)"
                            (onSelect)="addBoeToRoadManifest($event)"
                            [autoHighlight]="true">
              <ng-template let-value pTemplate="selectedItem">
          <span style="font-size:14px">{{value.transportDocNo}}</span>
        </ng-template>
            </p-autoComplete>
        <label for="boes">Search Waybill/Manifest No.</label>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </span>
  </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12">
            <p-table [columns]="cols" [value]="roadManifest.waybills" selectionMode="single" [(selection)]="selectedWaybill" (onRowSelect)="selectWaybill($event)" dataKey="lrn"
            >
              <ng-template pTemplate="caption">
                Waybills
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [style.width]="col.width">
                    {{col.header}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <td>
                    {{rowData.lrn}}
                  </td>
                  <td>
                    {{rowData.hawbNo}}
                  </td>
                  <td>
                    <button pButton type="button" icon="fa fa-times" class="danger"
                            (click)="deleteWaybill(rowIndex)" pTooltip="Delete"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Waybill Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.lrn"
                             required=true>
                      <label>LRN</label>
                      <div *ngIf="!selectedWaybill.lrn"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.mrn"
                             required=true>
                      <label>MRN</label>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <p-inputMask id="releaseDate" mask="99/99/9999" [(ngModel)]="selectedWaybill.releaseDate">
                   </p-inputMask>
                      <label for="releaseDate">Release Date</label>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.ucrNumber"
                             required=true>
                      <label>UCR</label>
                      <div *ngIf="!selectedWaybill.ucrNumber"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.mawbNo"
                             required=true>
                      <label>Manifest No.</label>
                      <div *ngIf="!selectedWaybill.mawbNo"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.hawbNo"
                             required=true>
                      <label>Waybill No.</label>
                      <div *ngIf="!selectedWaybill.hawbNo"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <p-inputMask id="mawbDate" mask="99/99/9999" [(ngModel)]="selectedWaybill.mawbDate">
                   </p-inputMask>
                      <label for="mawbDate">Manifest Date</label>
                      <div *ngIf="!selectedWaybill.mawbDate"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <p-inputMask id="hawbDate" mask="99/99/9999" [(ngModel)]="selectedWaybill.hawbDate">
                   </p-inputMask>
                      <label for="hawbDate">Waybill Date</label>
                      <div *ngIf="!selectedWaybill.hawbDate"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.cpc"
                             required=true>
                      <label>CPC</label>
                      <div *ngIf="!selectedWaybill.cpc"
                           class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Weights & Measures">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.grossMass"
                             required=true>
                      <label>Weight (kg)</label>
                  <div *ngIf="!selectedWaybill.grossMass"
                       class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Consignor Details">
                <digi-manifest-business-entity-search [businessEntity]="selectedWaybill.consignor"
                                                      roleType="12"
                                                      label="Consignor"
                                                      (updateBusinessEntity)="updateConsignor($event)"
                ></digi-manifest-business-entity-search>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Consignee Details">
                <digi-manifest-business-entity-search [businessEntity]="selectedWaybill.consignee"
                                                      roleType="2"
                                                      label="Consignee"
                                                      (updateBusinessEntity)="updateConsignee($event)"
                ></digi-manifest-business-entity-search>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Package Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.descriptionOfGoods"
                             required=true>
                      <label>Description of Goods</label>
                            <div *ngIf="!selectedWaybill.descriptionOfGoods"
                                 class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedWaybill.numberOfPackages"
                             required=true>
                      <label>Number of Packages</label>
                            <div *ngIf="!selectedWaybill.numberOfPackages"
                                 class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
                    </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
    <input type="text" pInputText [(ngModel)]="selectedWaybill.packageType"
           required=true>
                      <label>Package Type</label>
                            <div *ngIf="!selectedWaybill.packageType"
                                 class="ui-message ui-messages-error ui-corner-all">
                    This field is required</div>
  </span>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <div class="ui-inputgroup">
                            <span class="ui-float-label">
                            <p-dropdown [options]="cargoStatusCodes"
                                        [(ngModel)]="selectedWaybill.cargoStatusCode"
                                        [autoWidth]="false">
                            </p-dropdown>
                              <label>Cargo Status</label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4">
              <p-panel header="Port Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <digi-manifest-port label="Departure Port" [transportMethod]="roadManifest.transportMethodCode"
                                              [port]="selectedWaybill.departurePort"
                                              (portChange)="selectDeparturePort($event)"
                          ></digi-manifest-port>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <digi-manifest-port label="Destination Port" [transportMethod]="roadManifest.transportMethodCode"
                                              [port]="selectedWaybill.destinationPort"
                                              (portChange)="selectDestinationPort($event)"
                          ></digi-manifest-port>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Marks & Numbers">
      <digi-manifest-marks-and-numbers [marksAndNumbers]="selectedWaybill.marksAndNumbers"
                                       (updateMarksAndNumbers)="updateMarksAndNumbers($event)"></digi-manifest-marks-and-numbers>
    </p-tabPanel>
    <p-tabPanel header="Containers"></p-tabPanel>
  </p-tabView>
</div>
