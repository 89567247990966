import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {Company} from '../../../models/company';
import {Address} from '../../../models/file';
import {CompanyService} from '../../../services/company.service';
import {ClearingFile, ClearingInstructionFile} from '../../../models/clearingFile';

@Component({
  selector: 'digi-clearing-instruction-file',
  templateUrl: './clearing-instruction-file.component.html',
  styleUrls: ['./clearing-instruction-file.component.scss']
})
export class ClearingInstructionFileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() clearingFile: ClearingFile;
  @Input() clearingInstructionFile: ClearingInstructionFile;
  @Input() title: string;
  @Input() transportMethod: string;
  logo: string;
  companyAddress: string;
  companyName: string;
  companySubscription: Subscription;

  constructor(
    private companyService: CompanyService) {
  }

  ngOnInit() {
    this.clearingInstructionFile.importerName = this.clearingFile.importer.name;
    this.clearingInstructionFile.importerAddress = this.getFormattedAddress(this.clearingFile.importer.address);
    this.clearingInstructionFile.importerCode = this.clearingFile.importer.customsCode;
    this.clearingInstructionFile.importerVatNo = this.clearingFile.importer.taxRegNo;
    this.clearingInstructionFile.noOfPackages = this.clearingFile.transportDocument.measures.numberOfPackages;
    this.clearingInstructionFile.invoiceNo = this.clearingFile.transportDocument.supplierInvoice.invoiceNumber;
    this.clearingInstructionFile.tariffHeading = this.clearingFile.transportDocument.supplierInvoice.lines[0].tariffCode.code;
    this.clearingInstructionFile.rooNumber = this.clearingFile.transportDocument.supplierInvoice.lines[0].rooNumber ? this.clearingFile.transportDocument.supplierInvoice.lines[0].rooNumber : '';
    this.clearingInstructionFile.permitNo = this.clearingFile.transportDocument.supplierInvoice.lines[0].permit;
    this.clearingInstructionFile.placeOfClearance = this.clearingFile.customOffice.districtOfficeCode.trim();
    if (this.transportMethod ==='SEA') {
      this.clearingInstructionFile.vesselOrAirlineName = this.clearingFile.transportDocument.voyageDetails.customsRadioCallSign.vesselName;
      this.clearingInstructionFile.bolOrAwb = this.clearingFile.transportDocument.hawbNo; //Todo confirm this
      this.clearingInstructionFile.callSignOrFlightNo = this.clearingFile.transportDocument.voyageDetails.customsRadioCallSign.radioCallSign;
      this.clearingInstructionFile.containerNo = this.clearingFile.transportDocument.containers[0].containerNo;
    } else if (this.transportMethod ==='AIR') {
      this.clearingInstructionFile.vesselOrAirlineName = this.clearingFile.transportDocument.airline.name;
      this.clearingInstructionFile.bolOrAwb = this.clearingFile.transportDocument.straight ? this.clearingFile.transportDocument.mawbNo : this.clearingFile.transportDocument.mawbNo.concat(' ').concat(this.clearingFile.transportDocument.hawbNo);
      this.clearingInstructionFile.callSignOrFlightNo = this.clearingFile.transportDocument.flight;
    }
    this.clearingInstructionFile.destinationCode = 'ZA';
    this.companySubscription = this.companyService.getCompany().subscribe(
      (companies: Company[]) => {
        if (companies && companies.length) {
          this.logo = 'data:image/png;base64,' + companies[0].logo;
          const branch = this.clearingFile.branch ? companies[0].branches.find(el => el.code === this.clearingFile.branch) : companies[0].branches[0];
          this.companyAddress = this.getFormattedAddress(branch.invoiceDetails.physicalAddress);
          this.companyName = companies[0].name;
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clearingFile) {
      this.clearingFile = changes.clearingFile.currentValue;
    }

    if (changes.clearingInstructionFile) {
      this.clearingInstructionFile = changes.clearingInstructionFile.currentValue;
    }
  }

  ngOnDestroy() {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }

  private getFormattedAddress(address: Address): string {
    let formattedAddress = '';
    if (address) {
      formattedAddress = Object.values(address).filter(el => el != null).join(', ');
    }
    return formattedAddress;
  }
}
