import {BillOfEntryMarksAndNumbers, Container, Country, EDIMessage} from './billOfEntries';
import {CrewMember, LocalAgent, PortDetail, Transporter, VoyageDetails} from './file';
import {CustomsOffice} from '../../digi-business-entity/models/business-entity';
import {ExportConsignee, Exporter} from './exportFile';

export class CuscarType {
  type?: string;
  description?: string;
  label?: string;
}

export class PackType {
  code?: string;
  description?: string;
}

export class ManifestWaybill {
  mawbNo?: string;
  mawbDate?: string;
  hawbNo?: string;
  hawbDate?: string;
  lrn?: string;
  mrn?: string;
  numberOfPackages?: number;
  cargoStatusCode?: string;
  packageType?: string;
  descriptionOfGoods?: string;
  weight?: number;
  grossMass?: number;
  cpc?: string;
  containers?: Container[];
  marksAndNumbers?: BillOfEntryMarksAndNumbers[];
  departurePort?: PortDetail;
  destinationPort?: PortDetail;
  ucrNumber?: string;
  consignor?: Exporter;
  consignee?: ExportConsignee;
  releaseDate?: string;
}

export class RoadManifest {
  manifestFileNo?: string;
  manifestNo?: string;
  lrn?: string;
  carn?: string;
  transportMethodCode?: string;
  waybills?: ManifestWaybill[];
  contrl?: EDIMessage;
  cusres?: EDIMessage;
  cuscars?: string[];
  id?: string;
  _links?: any;
  crew: CrewMember[];
  cuscarType?: CuscarType;
  messageFunction?: number;
  type?: string;
  sequence: number;
  transporter?: Transporter;
  voyageDetails?: VoyageDetails;
  countryOfExport?: Country;
  exitPort?: PortDetail;
  destination?: Country;
  customOffice?: CustomsOffice;
  localAgent?: LocalAgent;
  arrivalDate?: string;
  masterTransportDocDate?: string;
  manifestType?: string;
  cargoType?: string;
  branch?: string;
  [k: string]: any;
}



export class RoadManifestCorrection extends RoadManifest {
  parent?: RoadManifest;

}
