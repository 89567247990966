import {Component, EventEmitter, Input, Output, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ExportFile} from '../../models/exportFile';
import {Commodity, PackageDetails, PackageDimensions, RateClass, RouteDetails, ExportTransportDocument, Measures} from '../../models/file';
import {RateClassService} from '../../services/rate-class.service';
import {CommodityService} from '../../services/commodity.service';

@Component({
  selector: 'digi-package-dims',
  templateUrl: './package-dims.component.html',
  styleUrls: ['./package-dims.component.scss']
})
export class PackageDimsComponent implements OnInit, OnChanges {
  @Input() exportFile: ExportFile;
  @Input() selectedConsolidationTransportDocumentPackageDetails: PackageDetails[];
  @Input() selectedConsolidationTransportDocumentMeasures: Measures;
  @Output() updatePackageDims = new EventEmitter();
  packageDetails: PackageDetails;
  filteredRateClasses: RateClass[];
  filteredCommodities: Commodity[];
  packageDimsCols: any[];
  unitsOptions: any[];
  selectedDimension = new PackageDimensions();

  constructor(
    private rateClassService: RateClassService,
    private commodityService: CommodityService
  ) { }

  ngOnInit() {
    this.unitsOptions = [{'label': 'kg', 'value': 'kg'}, {'label': 'lb', 'value': 'lb'}];
    this.packageDimsCols = [
      {header: 'Rate Class'},
      {header: 'Commodity Code'},
      {header: 'Kg/Lb'},
      {header: 'Weight'},
      {header: 'Volumetric Weight'},
      {header: 'HAWB Rate'},
      {header: 'IATA Rate'},
      {header: 'Hazardous'},
      {header: 'Refrigerated'},
      {header: 'As Agreed'},
      {header: ''}
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.exportFile && changes.exportFile.currentValue) {
      this.exportFile = changes.exportFile.currentValue;
      if (this.selectedConsolidationTransportDocumentPackageDetails) {
        if (this.selectedConsolidationTransportDocumentPackageDetails && this.selectedConsolidationTransportDocumentPackageDetails.length === 0) {
          this.createNewPackage();
        } else {
          this.packageDetails = this.selectedConsolidationTransportDocumentPackageDetails[0];
          this.selectedDimension = this.packageDetails.dimensions[0];
        }
      } else {
        if ((this.exportFile.transportDocument.packageDetails && this.exportFile.transportDocument.packageDetails.length === 0)
          || !this.exportFile.transportDocument.packageDetails) {
          this.createNewPackage();
        } else {
          this.packageDetails = this.exportFile.transportDocument.packageDetails[0];
          this.selectedDimension = this.packageDetails.dimensions[0];
        }
      }

      this.updatePackageDims.emit(this.packageDetails);
    }
    if (changes.selectedConsolidationTransportDocumentPackageDetails && changes.selectedConsolidationTransportDocumentPackageDetails.currentValue) {
      this.packageDetails = this.selectedConsolidationTransportDocumentPackageDetails[0];
      this.selectedDimension = this.packageDetails.dimensions[0];
    }
  }

  createNewPackage() {
    this.packageDetails = new PackageDetails();
    this.packageDetails.units = 'kg';
    this.addNewDimension();

    if (this.selectedConsolidationTransportDocumentPackageDetails) {
      if (this.exportFile && this.selectedConsolidationTransportDocumentPackageDetails) {
        if (this.selectedConsolidationTransportDocumentPackageDetails) {
          this.selectedConsolidationTransportDocumentPackageDetails.push(this.packageDetails);
        } else {
          this.selectedConsolidationTransportDocumentPackageDetails = [this.packageDetails];
        }
      }
    } else {
      if (this.exportFile && this.exportFile.transportDocument) {
        if (this.exportFile.transportDocument.packageDetails) {
          this.exportFile.transportDocument.packageDetails.push(this.packageDetails);
        } else {
          this.exportFile.transportDocument.packageDetails = [this.packageDetails];
        }
      }
    }
  }

  searchRateClass(event: any) {
    this.rateClassService.findByCode(event.query).subscribe(
      (data: RateClass[]) => this.filteredRateClasses = data
    );
  }

  searchCommodity(event: any) {
    this.commodityService.findByCode(event.query).subscribe(
      (data: Commodity[]) => this.filteredCommodities = data
    );
  }

  updateVolumetricWeight() {
    this.selectedDimension.volumetricWeight = Math.ceil((this.selectedDimension.quantity *
      this.selectedDimension.length * this.selectedDimension.width * this.selectedDimension.height) / 6000);
    this.packageDetails.volumetricWeight = this.packageDetails.dimensions
      .map(dimensions => dimensions.volumetricWeight)
      .reduce((sum, current) => current ? +sum + +current : +sum);

    if (this.selectedConsolidationTransportDocumentMeasures) {
      this.selectedConsolidationTransportDocumentMeasures.numberOfPackages = this.packageDetails.dimensions
                                .map(dimensions => dimensions.quantity)
                                .reduce((sum, current) => current ? +sum + +current : +sum);
      this.selectedConsolidationTransportDocumentMeasures.volume = this.packageDetails.volumetricWeight;
    }
  }

  updateConsolidationMeasures() {
    if (this.selectedConsolidationTransportDocumentMeasures) {
      this.selectedConsolidationTransportDocumentMeasures.grossWeight = this.packageDetails.actualWeight;
      this.selectedConsolidationTransportDocumentMeasures.chargeableWeight = this.packageDetails.chargeableWeight;
    }
  }

  defaultChargeableWeight() {
    if (!this.packageDetails.chargeableWeight) {
      this.packageDetails.chargeableWeight = this.packageDetails.actualWeight;
    }
  }

  addNewDimension() {
    this.selectedDimension = new PackageDimensions();
    this.selectedDimension.quantity = 0;
    this.selectedDimension.length = 0;
    this.selectedDimension.width = 0;
    this.selectedDimension.height = 0;
    this.selectedDimension.volumetricWeight = 0;
    if (this.packageDetails.dimensions && this.packageDetails.dimensions.length > 0) {
      this.packageDetails.dimensions.push(this.selectedDimension);
    } else {
      this.packageDetails.dimensions = [this.selectedDimension];
    }
  }

  deletePackageDetail(index) {
    if (this.selectedConsolidationTransportDocumentPackageDetails) {
      this.selectedConsolidationTransportDocumentPackageDetails.splice(index, 1);
      this.selectedConsolidationTransportDocumentPackageDetails = [...this.selectedConsolidationTransportDocumentPackageDetails];
    } else {
      this.exportFile.transportDocument.packageDetails.splice(index, 1);
      this.exportFile.transportDocument.packageDetails = [...this.exportFile.transportDocument.packageDetails];
    }

//     this.exportFile.transportDocument.packageDetails.splice(index, 1);
//     this.exportFile.transportDocument.packageDetails = [...this.exportFile.transportDocument.packageDetails];
  }

  deletePackageDimension(index) {
    this.packageDetails.dimensions.splice(index, 1);
    this.packageDetails.dimensions = [...this.packageDetails.dimensions];
    this.packageDetails.volumetricWeight = this.packageDetails.dimensions
      .map(dimensions => dimensions.volumetricWeight)
      .reduce((sum, current) => current ? +sum + +current : +sum);
  }

  selectPackageDetail() {
    this.selectedDimension = this.packageDetails.dimensions[0];
  }
}
