<p-panel>
  <div class="ui-g">
    <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.code}}"
                           pInputText readonly>
                    <label>Exporter Code</label>
                </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text" value="{{businessEntity.name}}"
                           pInputText readonly>
                    <label>Exporter Name</label>
                </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="businessEntity.defaultCustomsCode"
                                   pInputText>
                            <label>Customs Code</label>

                        </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
          <span class="md-inputfield">
                    <input type="text"
                           [(ngModel)]="businessEntity.defaultVatRegNo" pInputText>
                    <label>VAT Registration No</label>
                </span>
        </div>
      </div>
      <digi-customs-trader-type class="ui-g-12 ui-md-12" [customsTraderType]="exporter?.registrationType"
                                (onSelect)="selectTraderType($event)"></digi-customs-trader-type>
      <div class="ui-g-12 ui-md-6">
        <div class="ui-inputgroup">
        <span *ngIf="exporter?.registrationType?.id===174" class="md-inputfield">
          <input type="text" [(ngModel)]="exporter.idNumber" pInputText>
          <label>ID Number</label>
        </span>
          <span *ngIf="exporter?.registrationType?.id===53" class="md-inputfield">
          <input type="text" [(ngModel)]="exporter.passportNumber" pInputText>
          <label>Passport Number</label>
        </span>
        </div>
      </div>
      <digi-currency class="ui-g-12 ui-md-12" [currency]="exporter?.currency" (onSelect)="selectCurrency($event)"></digi-currency>
      <digi-country-of-origin class="ui-g-12 ui-md-12" [country]="exporter?.country"
                              (onSelect)="selectCountry($event)"></digi-country-of-origin>
      <div *ngIf="exporter.roleType === 12" class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                    <p-autoComplete id="receivingBank" [(ngModel)]="exporter.receivingBank"
                                    field="label"
                                    [dropdown]="true"
                                    [suggestions]="filteredReceivingBanks"
                                    (completeMethod)="searchReceivingBanks($event)"
                                    [autoHighlight]="true" (onSelect)="selectReceivingBank($event)" minLength="3"
                                    required="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.label}}</div>
                      </ng-template>
                    </p-autoComplete>
                <label for="receivingBank">Receiving bank</label>
              </span>
            </div>
          </span>
      </div>
      <div *ngIf="exporter.roleType === 12" class="ui-g-12 ui-md-12">
        <span class="md-inputfield">
            <div class="ui-inputgroup">
              <span class="ui-float-label">
                    <p-autoComplete id="bankName" [(ngModel)]="exporter.bank"
                                    [dropdown]="true"
                                    [suggestions]="filteredBanks"
                                    (completeMethod)="searchBanks($event)"
                                    [autoHighlight]="true" (onSelect)="selectBank($event)" minLength="3"
                                    required="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.name}}</div>
                      </ng-template>
                    </p-autoComplete>
                <label for="receivingBank">Bank Name</label>
              </span>
            </div>
          </span>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
          <p-checkbox [(ngModel)]="exporter.hasUnknownShipper" label="Unknown Shipper?"
                      binary="true"></p-checkbox>
        </div>
      </div>
    </div>
  </div>
</p-panel>
