<p-tabView orientation="left" styleClass="stepMenu" (onChange)="handleIndexChange($event)" [activeIndex]="activeIndex">
  <p-tabPanel header="Register" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-exports-file-header-section [transportMethod]="transportMethodCode" step="registration" [canAdd]="true" (add)="createNewFile()"
                                            (display)="openSequenceModal($event)">
          </digi-exports-file-header-section>
        </div>
        <!--/Clearing Form -->
        <digi-correction [fileType]="'exports'" *ngIf="isCorrection()"></digi-correction>
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g">
            <div class="ui-g-4">
              <p-panel header="Exporter Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="exportFile.exporter"
                                                   [showAddress]="true"
                                                   [roleName]="'Exporter'"
                                                   [roleID]="12"
                                                   [isNomination]="false"
                                                   (selectedBusinessEntity)="selectExporter($event)"
                      >
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <input type="text" pInputText [(ngModel)]="exportFile.exporterReference">
                            <label>Reference</label>
                          </span>
                        </div>
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Consignee Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="exportFile.exportConsignee"
                                                   [roleName]="'Consignee'"
                                                   [roleID]="2"
                                                   [showAddress]="true"
                                                   [isDropdown]="true"
                                                   [canAdd]="true"
                                                   [isNomination]="true"
                                                   [declarantRoleId]="12"
                                                   [declarantCode]="exportFile.exporter.code"
                                                   [businessEntities]="filteredExportConsignees"
                                                   (selectedBusinessEntity)="selectConsignee($event)"
                      >
                        <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                            <input type="text" pInputText [(ngModel)]="exportFile.consigneeReference">
                            <label>Reference</label>
                          </span>
                        </div>
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Debtor Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="exportFile.debtor"
                                                   [showAddress]="true"
                                                   [roleName]="'Debtor'"
                                                   [roleID]="1"
                                                   [isDropdown]="true"
                                                   [businessEntities]="filteredDebtors"
                                                   (selectedBusinessEntity)="selectDebtor($event)"
                      ></digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-4">
              <p-panel header="Local Agent Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="exportFile.localAgent"
                                                   [roleName]="'Local Agent'"
                                                   [roleID]="4"
                                                   [showAddress]="true"
                                                   [isNomination]="false"
                                                   (selectedBusinessEntity)="selectLocalAgent($event)"
                      >
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4">
              <p-panel header="Customs Office">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <digi-customs-office [customOffice]="exportFile.customOffice"
                                               [isRequired]=true
                                               (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br *ngIf="isAirTransport()">
              <p-panel *ngIf="isAirTransport() && exportFile.transportDocument" header="Degroup/Transit Depot">
                <digi-hawb-degroup-transit-section [transportDocument]="exportFile.transportDocument">
                </digi-hawb-degroup-transit-section>
              </p-panel>
              <br>
              <digi-registration-references-section
                [file]="exportFile"></digi-registration-references-section>
            </div>
            <div class="ui-g-4">
              <p-panel header="Assessment & SLA Date / Promised Date">
                <div class="ui-g ui-fluid">
                  <div class="ui-g-12 ui-md-12"></div>
                  <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                          <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="Assessment Date"
                                       [(ngModel)]="exportFile.assessmentDate"></p-inputMask>
                          <label>Assessment Date</label>
                        </span>
                  </div>
                  <div class="ui-g-12 ui-md-12"></div>
                  <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                        <p-inputMask mask="99/99/9999" slotChar="dd/mm/yyyy" placeholder="SLA Date/Promised Date "
                                     [(ngModel)]="exportFile.slaDate"></p-inputMask>
                        <label>SLA Date/Promised Date</label>
                      </span>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="Shipping Description">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [(ngModel)]="exportFile.shippingDescription" [rows]="3" [cols]="50"
                                    placeholder="Your Shipping Description"
                                    style="resize: none">
                          </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
              <br>
              <p-panel header="File Inco Term">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <digi-file-inco-term [clearingFile]="exportFile"></digi-file-inco-term>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
          <div class="ui-g">
            <div class="ui-g-12">
              <p-pickList [source]="availableInstructions" [target]="selectedInstructions"
                          sourceHeader="Available Instruction" targetHeader="Selected Instruction" [responsive]="true"
                          [showSourceControls]="false" [showTargetControls]="false"
                          (onMoveToTarget)="selectClearingOption($event)"
                          (onMoveToSource)="onMoveToSource($event)"
                          [dragdrop]="true" dragdropScope="instructions"
                          (onTargetSelect)="selectedClearingInstruction($event)"
              >
                <ng-template let-instruction pTemplate="item">
                  <div class="ui-helper-clearfix">
                    <div>
                      {{instruction.customsPurpose.customsPurposeCode}} :
                      {{instruction.customsPurpose.customsPurposeName}}
                    </div>
                  </div>
                </ng-template>
              </p-pickList>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <ng-container *ngIf="isAirTransport()">
    <p-tabPanel header="Booking">
      <ng-template pTemplate="content">
        <div class="ui-g-12 ui-g-nopad">
          <!-- Clearing Form -->
          <div class="ui-g-12 pt-0">
            <digi-exports-file-header-section step="registration" [canAdd]="true" (add)="createNewFile()"
                                              (display)="openSequenceModal($event)">
            </digi-exports-file-header-section>
          </div>
          <digi-booking [exportFile]="exportFile"></digi-booking>
        </div>
      </ng-template>
    </p-tabPanel>
  </ng-container>
  <ng-container *ngIf="isAirTransport()">
    <p-tabPanel header="Routing" >
      <ng-template pTemplate="content">
        <div class="ui-g-12 ui-g-nopad">
          <digi-routing [exportFile]="exportFile"></digi-routing>
        </div>
      </ng-template>
    </p-tabPanel>
  </ng-container>
  <ng-container *ngIf="isAirTransport()">
    <p-tabPanel header="Package Dims">
      <ng-template pTemplate="content">
        <div *ngIf="!isConsolidationShipment() || !selectedConsolidationTransportDocument" class="ui-g-12 ui-g-nopad">
          <digi-package-dims [exportFile]="exportFile"></digi-package-dims>
        </div>
        <div *ngIf="isConsolidationShipment() && selectedConsolidationTransportDocument" class="ui-g-12 ui-g-nopad">
          <digi-package-dims [exportFile]="exportFile" [selectedConsolidationTransportDocumentPackageDetails]="selectedConsolidationTransportDocument.packageDetails"
                             [selectedConsolidationTransportDocumentMeasures]="selectedConsolidationTransportDocument.measures"></digi-package-dims>
        </div>
      </ng-template>
    </p-tabPanel>
  </ng-container>
  <ng-container *ngIf="isAirTransport()">
    <p-tabPanel header="MAWB" >
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-exports-file-header-section step="registration" [canAdd]="true" (add)="createNewFile()"
                                            (display)="openSequenceModal($event)">
          </digi-exports-file-header-section>
        </div>
        <digi-air-waybill [exportFile]="exportFile"></digi-air-waybill>
      </div>
    </p-tabPanel>
  </ng-container>
  <ng-container *ngIf="isAirTransport() && (isBackToBackShipment() || isConsolidationShipment())">
    <p-tabPanel header="HAWB" >
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-exports-file-header-section step="registration" [canAdd]="true" (add)="createNewFile()"
                                            (display)="openSequenceModal($event)">
          </digi-exports-file-header-section>
        </div>
        <digi-air-waybill *ngIf="isBackToBackShipment()" [isHouseBill]="true" [exportFile]="exportFile" ></digi-air-waybill>
        <digi-air-waybill *ngIf="isConsolidationShipment()" [isHouseBill]="true" [isConsolidation]="true" [exportFile]="exportFile" (onNewHouseBillAdded)="validateAndSaveFile()" (onSelect)="selectConsolidationHawb($event)"></digi-air-waybill>
      </div>
    </p-tabPanel>
  </ng-container>
  <ng-container *ngIf="isSeaTransport()">
    <p-tabPanel [disabled]="!exportFile.id" header="Shipping Order">
      <ng-template pTemplate="content">
        <div class="ui-g-12 ui-g-nopad">
          <digi-exports-file-header-section step="registration" [canDisable]="false" [canAdd]="true" [canCreateVoC]="false" (add)="createShippingOrder()">
          </digi-exports-file-header-section>
          <digi-shipping-order [shippingOrder]="exportFile.shippingOrder" *ngIf="exportFile.shippingOrder"></digi-shipping-order>
        </div>
      </ng-template>
    </p-tabPanel>
  </ng-container>
</p-tabView>
<digi-clearing-instruction-modal [display]="display"
                                 [customsProcedures]="customsProcedures"
                                 [showCustomsProcedures]="showCustomsProcedures"
                                 [fileType]="'exports'"
                                 (onCloseCustomProcedureModal)="onCloseCustomProcedureModal($event)"
                                 (displayChange)="onDialogClose($event)">
</digi-clearing-instruction-modal>
<digi-sequence-modal
  [fileType]="'exports'"
  [display]="openSequence"
  (displayChange)="onSequenceClose($event)">
</digi-sequence-modal>
