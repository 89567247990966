import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { of } from 'rxjs';
import { ExportFile } from '../models/exportFile';
import { ShareDataService } from './share-data.service';
import { ExportFileBehaviourSubject } from '../../../subjects/exportfile-behaviour-subject';
import { map, tap } from 'rxjs/operators';
import { ClearingInstructionBehaviorSubject } from '../../../subjects/clearingInstruction-behavior-subject';
import { ContainerBehaviorSubject } from '../../../subjects/container-behavior-subject';
import { TransportDocumentBehaviourSubject } from '../../../subjects/transport-document-behaviour-subject';
import { SupplierInvoiceBehaviourSubject } from '../../../subjects/supplierInvoice-behaviour-subject';
import { SupplierInvoiceLineBehaviourSubject } from '../../../subjects/supplier-invoice-line-behaviour-subject.service';
import { BillOfEntryService } from './bill-of-entry.service';
import { BillOfEntryBehaviourSubject } from '../../../subjects/billOfEntry-behaviour-subject';
import { BillOfEntryLineBehaviourSubject } from '../../../subjects/billOfEntryLine-behaviour-subject';
import { AdditionalInfoBehaviourSubject } from '../../../subjects/addittional-info-behaviour-subject';
import { UniqueConsignmentBehaviourSubject } from '../../../subjects/unique-consignment-reference-subject';
import { FileService } from './file.service';
import { AuthorisationService } from '../../../subjects/authorisation-behaviour-subject';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "./share-data.service";
import * as i4 from "../../../subjects/exportfile-behaviour-subject";
import * as i5 from "../../../subjects/clearingInstruction-behavior-subject";
import * as i6 from "../../../subjects/container-behavior-subject";
import * as i7 from "../../../subjects/transport-document-behaviour-subject";
import * as i8 from "../../../subjects/supplierInvoice-behaviour-subject";
import * as i9 from "../../../subjects/supplier-invoice-line-behaviour-subject.service";
import * as i10 from "./bill-of-entry.service";
import * as i11 from "../../../subjects/billOfEntry-behaviour-subject";
import * as i12 from "../../../subjects/billOfEntryLine-behaviour-subject";
import * as i13 from "../../../subjects/addittional-info-behaviour-subject";
import * as i14 from "../../../subjects/unique-consignment-reference-subject";
import * as i15 from "./file.service";
import * as i16 from "../../../subjects/authorisation-behaviour-subject";
var ExportFileService = /** @class */ (function () {
    function ExportFileService(http, config, shareDataService, exportFileBehaviourSubject, clearingInstructionBehaviorSubject, containersBehaviourSubject, transportDocumentBehaviourSubject, supplierInvoiceBehaviourSubject, supplierInvoiceLineBehaviourSubject, billOfEntryService, billOfEntryBehaviourSubject, billOfEntryLineBehaviourSubject, additionalInfoBehaviourSubject, containerBehaviourSubject, uniqueConsignmentBehaviourSubject, fileService, authorisationService) {
        this.http = http;
        this.config = config;
        this.shareDataService = shareDataService;
        this.exportFileBehaviourSubject = exportFileBehaviourSubject;
        this.clearingInstructionBehaviorSubject = clearingInstructionBehaviorSubject;
        this.containersBehaviourSubject = containersBehaviourSubject;
        this.transportDocumentBehaviourSubject = transportDocumentBehaviourSubject;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.supplierInvoiceLineBehaviourSubject = supplierInvoiceLineBehaviourSubject;
        this.billOfEntryService = billOfEntryService;
        this.billOfEntryBehaviourSubject = billOfEntryBehaviourSubject;
        this.billOfEntryLineBehaviourSubject = billOfEntryLineBehaviourSubject;
        this.additionalInfoBehaviourSubject = additionalInfoBehaviourSubject;
        this.containerBehaviourSubject = containerBehaviourSubject;
        this.uniqueConsignmentBehaviourSubject = uniqueConsignmentBehaviourSubject;
        this.fileService = fileService;
        this.authorisationService = authorisationService;
    }
    ExportFileService.prototype.save = function (exportFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (exportFile._links && exportFile._links.self) {
            return this.http.put(this.config.filesApiEndpoint + 'api/exportFiles/' + exportFile.id, JSON.stringify(exportFile), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/exportFiles', JSON.stringify(exportFile), { headers: headers });
        }
    };
    ExportFileService.prototype.saveExportsFile = function (exportFile, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        var _this = this;
        this.fileService.setIsFileSaving(true);
        if (!exportFile.type) {
            exportFile.type = 'ExportFile';
            return this.save(exportFile).pipe(tap(function (data) {
                _this.updateFileAfterSaving(data, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine);
                _this.fileService.setIsFileSaving(false);
            }));
        }
        else {
            return this.save(exportFile).pipe(tap(function (data) {
                _this.updateFileAfterSaving(data, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine);
                _this.fileService.setIsFileSaving(false);
            }));
        }
    };
    ExportFileService.prototype.updateFileAfterSaving = function (data, selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        this.exportFileBehaviourSubject.addExportFile(data);
        if (data.transportDocument) {
            this.transportDocumentBehaviourSubject.addTransportDocument(data.transportDocument);
            this.navigateSupplierInvoices(data.transportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine);
        }
    };
    ExportFileService.prototype.navigateSupplierInvoices = function (selectedTransportDocument, selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        if (selectedTransportDocument) {
            if (selectedSupplierInvoice) {
                var nextSupplierInvoice = selectedTransportDocument.supplierInvoices
                    .find(function (s) { return s.invoiceDate === selectedSupplierInvoice.invoiceDate && s.invoiceNumber === selectedSupplierInvoice.invoiceNumber; });
                if (nextSupplierInvoice) {
                    this.supplierInvoiceBehaviourSubject.addSupplierInvoice(nextSupplierInvoice);
                }
                else {
                    this.supplierInvoiceBehaviourSubject.addSupplierInvoice(selectedTransportDocument.supplierInvoices[0]);
                }
                this.navigateSupplierInvoiceLines(selectedSupplierInvoice, selectedSupplierInvoiceLine);
            }
        }
    };
    ExportFileService.prototype.navigateSupplierInvoiceLines = function (selectedSupplierInvoice, selectedSupplierInvoiceLine) {
        if (selectedSupplierInvoice) {
            var nextSupplierInvoiceLine = selectedSupplierInvoice.lines.find(function (l) { return selectedSupplierInvoiceLine && l.lineNo === selectedSupplierInvoiceLine.lineNo; });
            if (nextSupplierInvoiceLine) {
                this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(nextSupplierInvoiceLine);
            }
            else {
                this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(selectedSupplierInvoice.lines[0]);
            }
        }
    };
    ExportFileService.prototype.createNewFile = function (transportMethod) {
        var exportFile = this.shareDataService.buildExportFile(transportMethod);
        this.exportFileBehaviourSubject.addExportFile(exportFile);
        this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
        this.transportDocumentBehaviourSubject.addTransportDocument(exportFile.transportDocument);
        this.supplierInvoiceBehaviourSubject.addSupplierInvoice(null);
        this.supplierInvoiceLineBehaviourSubject.addSupplierInvoiceLine(null);
        this.containerBehaviourSubject.addContainer(null);
        this.supplierInvoiceBehaviourSubject.addCountry(null);
        this.supplierInvoiceBehaviourSubject.addCurrency(null);
        this.supplierInvoiceLineBehaviourSubject.addCountry(null);
        this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('exports'));
        this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
        this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(null);
    };
    ExportFileService.prototype.loadExportFile = function (fileNumber, transportMethod, branchCode) {
        if (fileNumber === '') {
            return of([]);
        }
        var params = "fileNumber=" + fileNumber;
        if (transportMethod) {
            params += "&transportMethod=" + transportMethod;
        }
        if (branchCode) {
            params += "&branchCode=" + branchCode;
            return this.http
                .get(this.config.filesApiEndpoint + "api/exportFiles/search/findByFileNumberOrReferenceFileNoAndBranchCode?" + params)
                .pipe(map(function (response) { return response['_embedded'].exportFiles; }));
        }
        else {
            return this.http
                .get(this.config.filesApiEndpoint + "api/exportFiles/search/fileNumberOrReferenceFileNoStartsWith?" + params)
                .pipe(map(function (response) { return response['_embedded'].exportFiles; }));
        }
    };
    ExportFileService.prototype.selectExportFile = function (value) {
        var _this = this;
        this.createNewFile();
        this.exportFileBehaviourSubject.addExportFile(value);
        this.transportDocumentBehaviourSubject.addTransportDocument(value.transportDocument);
        if (value.transportDocument && value.transportDocument.containers && value.transportDocument.containers.length > 0) {
            this.containerBehaviourSubject.addContainer(value.transportDocument.containers[0]);
        }
        if (value.transportDocument && value.transportDocument.supplierInvoices && value.transportDocument.supplierInvoices.length > 0) {
            this.supplierInvoiceBehaviourSubject.addSupplierInvoice(value.transportDocument.supplierInvoices[0]);
            if (value.transportDocument.supplierInvoices[0].lines.length > 0) {
                this.supplierInvoiceLineBehaviourSubject
                    .addSupplierInvoiceLine(value.transportDocument.supplierInvoices[0].lines[0]);
            }
        }
        if (value.clearingInstructions.length) {
            this.clearingInstructionBehaviorSubject.addClearingInstructions(value.clearingInstructions);
        }
        this.billOfEntryService
            .findBillOfEntryByExportFile_id(value.id)
            .subscribe(function (data) {
            if (data._embedded.exportBillOfEntries.length > 0) {
                _this.billOfEntryBehaviourSubject.addBillOfEntry(data._embedded.exportBillOfEntries[0]);
                _this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(data._embedded.exportBillOfEntries[0].lines[0]);
                _this.additionalInfoBehaviourSubject.addAdditionalInfo(data._embedded.exportBillOfEntries[0].lines[0].additionalInformation);
                _this.billOfEntryBehaviourSubject.addBillsOfEntry(data._embedded.exportBillOfEntries);
            }
        });
    };
    ExportFileService.prototype.loadAllExportFileCorrectionsIncludingOriginalExportFile = function (clearingFileNumber, transportMethod) {
        if (clearingFileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/exportFileCorrections/search/fileNumberOrReferenceFileNoStartsWith?fileNumber=" + clearingFileNumber + "&transportMethod=" + transportMethod)
            .pipe(map(function (response) { return response['_embedded'].exportFileCorrections; }));
    };
    ExportFileService.prototype.findAllSequences = function (clearingFileNumber) {
        if (clearingFileNumber === '') {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/exportFileCorrections/search/findSequencesByClearingFileNumber?clearingFileNumber="
            + clearingFileNumber + '&projection=sequenceAndClearingFileNumber')
            .pipe(map(function (response) { return response['_embedded'].exportFileCorrections; }));
    };
    ExportFileService.prototype.findByClearingFileNumberAndSequence = function (fileNumber, sequence) {
        if (fileNumber === '' && sequence === null) {
            return of([]);
        }
        return this.http
            .get(this.config.filesApiEndpoint + "api/exportFileCorrections/search/clearingFileNumberAndSequence?clearingFileNumber=" + fileNumber
            + '&sequence=' + sequence)
            .pipe(map(function (response) { return response['_embedded'].exportFileCorrections; }));
    };
    ExportFileService.prototype.findClearingFileNumbersByInvoiceNo = function (invoiceNumber, fileNumber) {
        return this.http.get(this.config.filesApiEndpoint + "api/exportFiles/search/findClearingFileNumbersByInvoiceNo?clearingFileNumber=" + fileNumber + "&invoiceNo=" + invoiceNumber)
            .pipe(map(function (response) { return response.map(function (el) { return el.clearingFileNumber; }); }));
    };
    ExportFileService.prototype.findClearingFileNumbersByHawbNo = function (hawbNo, fileNumber) {
        return this.http.get(this.config.filesApiEndpoint + "api/exportFiles/search/findClearingFileNumbersByHawbNo?clearingFileNumber=" + fileNumber + "&hawbNo=" + hawbNo)
            .pipe(map(function (response) { return response.map(function (el) { return el.clearingFileNumber; }); }));
    };
    ExportFileService.prototype.findClearingFileNumbersByMawbNo = function (mawbNo, fileNumber) {
        return this.http.get(this.config.filesApiEndpoint + "api/exportFiles/search/findClearingFileNumbersByMawbNo?clearingFileNumber=" + fileNumber + "&mawbNo=" + mawbNo)
            .pipe(map(function (response) { return response.map(function (el) { return el.clearingFileNumber; }); }));
    };
    ExportFileService.prototype.findByRef = function (href) {
        return this.http.get(href.replace('\{\?projection\}', ''));
    };
    ExportFileService.prototype.loadFiles = function (id) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/exportFiles/" + id + '/airWaybills');
    };
    ExportFileService.prototype.loadConsolidationFiles = function (id, hawbNo) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/exportFiles/" + id + '/' + hawbNo + '/airWaybills');
    };
    ExportFileService.prototype.createInvoiceLines = function (partDetails, exportFileId, mawbNo, invoiceNumber) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.put(this.config.filesApiEndpoint + "api/exportFiles/" + exportFileId + "/supplierInvoices/upload?mawbNo=" + mawbNo + "&invoiceNo=" + invoiceNumber, JSON.stringify(partDetails), { headers: headers });
    };
    ExportFileService.ngInjectableDef = i0.defineInjectable({ factory: function ExportFileService_Factory() { return new ExportFileService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.ShareDataService), i0.inject(i4.ExportFileBehaviourSubject), i0.inject(i5.ClearingInstructionBehaviorSubject), i0.inject(i6.ContainerBehaviorSubject), i0.inject(i7.TransportDocumentBehaviourSubject), i0.inject(i8.SupplierInvoiceBehaviourSubject), i0.inject(i9.SupplierInvoiceLineBehaviourSubject), i0.inject(i10.BillOfEntryService), i0.inject(i11.BillOfEntryBehaviourSubject), i0.inject(i12.BillOfEntryLineBehaviourSubject), i0.inject(i13.AdditionalInfoBehaviourSubject), i0.inject(i6.ContainerBehaviorSubject), i0.inject(i14.UniqueConsignmentBehaviourSubject), i0.inject(i15.FileService), i0.inject(i16.AuthorisationService)); }, token: ExportFileService, providedIn: "root" });
    return ExportFileService;
}());
export { ExportFileService };
