import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';
import {ClearingInstruction, PortDetail} from '../../models/file';
import {ClearingFile} from '../../models/clearingFile';
import {TransportMethodsService} from '../../services/transport-methods.service';
import {ShareDataService} from '../../services/share-data.service';
import {InternationalPortService} from '../../services/international-port.service';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {FileType} from '../../models/enumerations';

@Component({
  selector: 'digi-clearing-instruction-modal',
  templateUrl: './clearing-instruction-modal.component.html',
  styleUrls: ['./clearing-instruction-modal.component.scss']
})
export class ClearingInstructionModalComponent implements OnInit, OnDestroy {
  @Input('display') display;
  @Output() displayChange = new EventEmitter();
  @Output() onCloseCustomProcedureModal = new EventEmitter();
  @Input('customsProcedures') customsProcedures;
  clearingInstructions: ClearingInstruction [];
  @Input() showCustomsProcedures;
  @Input('rebateUsers') rebateUsers;
  @Input('rebateUserAddresses') rebateUserAddresses;
  @Input() fileType: FileType;
  filteredPortDetails = [];
  clearingFile: ClearingFile;
  subscription: Subscription;
  clearingInstructionSubscription: Subscription;
  select: any[] = [];
  cols: any[];
  selected: ClearingInstruction;
  showAdditionalFieldForRoad;
  selectedClearingInstruction: ClearingInstruction;
  exportFileSubscription: Subscription;
  methods;
  exporterConsignors: any[];
  warehouses: any[];
  removers: any[];
  guarantors: any[];

  constructor(private transportMethodsService: TransportMethodsService,
              private shareDataService: ShareDataService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private internationalPortService: InternationalPortService,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject
  ) {
  }

  ngOnInit() {
    const clearingFileObservable = this.clearingFileBehaviourSubject.getClearingFile();
    const instructionsObservable = this.clearingInstructionBehaviorSubject.getClearingInstructions();
    const selectedInstructionObservable = this.clearingInstructionBehaviorSubject.getInstruction();
    this.subscription = combineLatest(clearingFileObservable, instructionsObservable, selectedInstructionObservable).subscribe((tuple: any[]) => {
      if (tuple[0]) {
        this.clearingFile = tuple[0];
      }
      if (tuple[1]) {
        this.clearingInstructions = tuple[1];
      }
      if (tuple[2]) {
        this.selectedClearingInstruction = tuple[2];
        if (this.isWarehouse() && !this.selectedClearingInstruction.removalTransportMethod) {
          this.selectedClearingInstruction.removalTransportMethod = Object.assign({}, {
            code: '0',
            description: 'UNKNOWN   ',
            method: 'UKN'
          });
        } else {
          this.selectedClearingInstruction.removalTransportMethod = Object.assign({}, this.selectedClearingInstruction.removalTransportMethod);
        }
        if (this.fileType === 'exports') {
          this.exportFileSubscription = this.exportFileBehaviourSubject.getExportFile().subscribe(
            ef => {
              if (ef && ef.exportConsignee && ef.exportConsignee.country) {
                this.selectedClearingInstruction.destination = ef.exportConsignee.country;
              }
            }
          );
        }
      }
    });
    this.clearingInstructionBehaviorSubject.getRebateUsers().subscribe(data => this.rebateUsers = data);
    this.exportFileBehaviourSubject.getExporterConsignor().subscribe(data  => this.exporterConsignors = data);
    this.exportFileBehaviourSubject.getWarehouses().subscribe(data => this.warehouses = data);
    this.exportFileBehaviourSubject.getRemovers().subscribe(data => this.removers = data);
    this.exportFileBehaviourSubject.getGuarantors().subscribe(data => this.guarantors = data);
    this.cols = [
      {field: 'customsProcedureCode', header: 'Code', width: '10%'},
      {field: 'customsProcDesc', header: 'Description', width: '90%'}];
  }

  onClose() {
    this.displayChange.emit(false);
  }

  onRowSelect(value) {
    const customsProcedure = this.customsProcedures[value.index];
    this.selectedClearingInstruction.customsProcedureCode = customsProcedure.customsProcedureCode;
    this.selectedClearingInstruction.customsProcDesc = customsProcedure.customsProcDesc;
    this.selectedClearingInstruction.procedureCategoryCode = customsProcedure.procedureCategory.procedureCategoryCode;
    this.selectedClearingInstruction.pcDescription = customsProcedure.procedureCategory.pcDescription;
    this.selected = this.selectedClearingInstruction;
    this.clearingInstructionBehaviorSubject.addInstruction(this.selectedClearingInstruction);
  }

  onHide(value) {
    this.displayChange.emit(false);
  }

  isRIT() {
    if (this.selectedClearingInstruction && this.selectedClearingInstruction.customsPurpose && this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'RIT') {
      return true;
    } else {
      return false;
    }
  }

  isRIB() {
    return this.selectedClearingInstruction && this.selectedClearingInstruction.customsPurpose && this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'RIB';
  }

  isRITRIB() {
      if (this.selectedClearingInstruction && (this.selectedClearingInstruction.customsPurpose && this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'RIB' ||
        this.selectedClearingInstruction.customsPurpose && this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'RIT')) {
        return true;
      }
      return false;
  }

  isIRGR() {
    if (this.selectedClearingInstruction && this.selectedClearingInstruction.customsPurpose
      && this.selectedClearingInstruction.customsPurpose.customsPurposeCode) {
      if (this.selectedClearingInstruction.customsPurpose.customsPurposeCode.trim() === 'IR' ||
        this.selectedClearingInstruction.customsPurpose.customsPurposeCode.trim() === 'GR') {
        return true;
      }
      return false;
    }
  }

  search(event) {
    if (this.selectedClearingInstruction.transportMethod.method === 'AIR') {
      this.internationalPortService.findTransportDetailsWithCodeOrNameAndTransportMethodIsAir(event.query).subscribe(data => {
        this.filteredPortDetails = data;
      });
    } else {
      this.internationalPortService.findTransportDetailsWithCodeOrNameAndTransportMethodIsNotAir(event.query).subscribe(data => {
        this.filteredPortDetails = data;
      });
    }
  }

  selectPort(value) {
    this.selectedClearingInstruction.portDetails = new PortDetail();
    this.selectedClearingInstruction.portDetails.code = value.code;
    this.selectedClearingInstruction.portDetails.name = value.name;
    this.selectedClearingInstruction.portDetails.label = value.label;
    this.selectedClearingInstruction.portDetails.countryCode = value.transportDetails.countryCode;
    this.selectedClearingInstruction.portDetails.countryName = value.transportDetails.countryName;
    this.selectedClearingInstruction.portDetails.masPortCode = value.transportDetails.countryName;
    this.selectedClearingInstruction.portDetails.transportMethod = value.transportDetails.id.transportMethod;
  }

  setCustomsVatRequired(event) {
    this.selectedClearingInstruction.customVatRequired = !this.selectedClearingInstruction.customVatRequired;
  }

  setConsolidateBoELines(event) {
    this.selectedClearingInstruction.consolidateBoeLines = !this.selectedClearingInstruction.consolidateBoeLines;
  }

  selectCountryOfOrigin(event) {
    this.selectedClearingInstruction.destination = Object.assign({}, event);
  }

  customsProceduresPopup() {
    this.showCustomsProcedures = true;
  }

  customsProceduresPopupHide() {
    this.showCustomsProcedures = false;
    this.onCloseCustomProcedureModal.emit(false);
  }

  selectRemovalTransportMethod(value) {
    this.selectedClearingInstruction.removalTransportMethod = value;
    this.showAdditionalFieldForRoad = value.method === 'ROA';
  }

  selectTransportMethod(value) {
    this.selectedClearingInstruction.transportMethod = value;
  }

  isRoadRemovalTransportMethod() {
    if (this.selectedClearingInstruction
      && this.selectedClearingInstruction.removalTransportMethod
      && this.selectedClearingInstruction.removalTransportMethod.method === 'ROA') {
      return true;
    } else {
      return false;
    }
  }

  updateCustomsOffice(customOffice) {
    this.selectedClearingInstruction.customOffice = customOffice;
  }

  ngOnDestroy() {
    this.displayChange.unsubscribe();
    this.subscription.unsubscribe();
    if (this.exportFileSubscription) {
      this.exportFileSubscription.unsubscribe();
    }
  }

  isWarehouse() {
    return this.selectedClearingInstruction && this.selectedClearingInstruction.customsPurpose &&
      (this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'WH ' ||
        this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'WE ');
  }

  searchRemovalTransportMethod(event: any) {
    this.transportMethodsService.findTransportMethods().subscribe(
      transportMethods => this.methods = transportMethods
    );
  }

  isXe() {
    return this.selectedClearingInstruction && this.selectedClearingInstruction.customsPurpose && this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'XE ';
  }

  isXIR() {
    return this.selectedClearingInstruction && this.selectedClearingInstruction.customsPurpose && this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'XIR';
  }

  isXGR() {
    return this.selectedClearingInstruction && this.selectedClearingInstruction.customsPurpose && this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'XGR';
  }

  isXRW() {
    return this.selectedClearingInstruction && this.selectedClearingInstruction.customsPurpose && this.selectedClearingInstruction.customsPurpose.customsPurposeCode === 'XRW';
  }

  selectExporterConsignor(value) {
    this.selectedClearingInstruction.exporterConsignor = value;
  }

  selectWarehouse(value) {
    this.selectedClearingInstruction.warehouse = value;
  }

  selectDestinationWarehouse(value) {
    this.selectedClearingInstruction.destinationWarehouse = value;
  }

  selectToImporter(value) {
    this.selectedClearingInstruction.toImporterConsignee = value;
  }

  selectRemover(value: any) {
    this.selectedClearingInstruction.remover = value;
  }

  selectGuarantor(value: any) {
    this.selectedClearingInstruction.guarantor = value;
  }

  selectOwner(value: any) {
    this.selectedClearingInstruction.owner.code = value.code;
    this.selectedClearingInstruction.owner.name = value.name;
    this.selectedClearingInstruction.owner.label = value.label;
    this.selectedClearingInstruction.owner.address = value.address;
    this.selectedClearingInstruction.owner.currency = value.currency;
    this.selectedClearingInstruction.owner.customsCode = value.defaultCustomsCode;
    this.selectedClearingInstruction.owner.taxRegNo = value.defaultVatRegNo;
  }

  selectRebate(value: any) {
    this.selectedClearingInstruction.rebateUser = value;
  }
}


