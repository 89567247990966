import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {
  Address,
  Airline,
  Certificate,
  CertificateType,
  ClearingInstruction,
  CollectPrepaid,
  Container,
  ContainerRunningTotals,
  ContainerSize,
  ContainerSpecification,
  ContainerType,
  Country,
  Currency,
  CustomOffice,
  CustomsPurpose,
  CustomsPurposeFile,
  CustomsRadioCallSign,
  Debtor,
  DefaultValues,
  DepotTerminal, Document,
  ExportTransportDocument,
  File, ForwardFile,
  Freight,
  HasSundryCharge,
  IncoTerm,
  Information,
  LocalAgent,
  LocalAgentCreditor,
  LocalAgentRegistrations,
  LocalForwarder,
  Measures,
  OceanLine,
  OceanLineDetails,
  OverseasAgent,
  Permit,
  PortDetail,
  PortTo,
  PreviousBoeDetails,
  PurchaseTerm,
  RateBasedCharge,
  Reference,
  Remover,
  RunningTotals,
  SealDetails,
  Solas,
  SundryCharge,
  Supplier,
  SupplierInvoice,
  SupplierInvoiceLine,
  TariffCode,
  TariffFormula,
  TariffParameter,
  TradeAgreement,
  TransportDocument,
  TransportMethod,
  UserInterfaceCheckbox,
  UserInterfaceDefaults,
  ValueBasedCharge,
  VoyageDetails
} from '../models/file';
import {ClearingFile, Importer, ReportFile, PaymentFile, ClearingInstructionFile} from '../models/clearingFile';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  AbstractBoE,
  BillOfEntry,
  BillOfEntryLine,
  BillOfEntryPermit,
  BoEInvoiceDetails,
  Destination,
  ExBondBillOfEntry,
  ExBondBillOfEntryLine,
  Export,
  ExportBillOfEntry,
  ExportBillOfEntryLine
} from '../models/billOfEntries';


import {BillsOfEntry, ExBondBillsOfEntry, ExportBillsOfEntry} from '../models/billsOfEntry';
import {ExportConsignee, Exporter, ExportFile, ShippingOrder} from '../models/exportFile';
import {FileType} from '../models/enumerations';
import {ReceivingBank} from '../../digi-business-entity/models/business-entity';
import {CompanyService} from './company.service';
import {AgentService} from './agent.service';
import {BusinessEntityService} from '../../digi-business-entity/services/business-entity.service';
import {CustomOfficeService} from './custom-office.service';
import {ExBondFile, Warehouse} from '../models/exBondFile';
import {AuthorisationService} from '../../../subjects/authorisation-behaviour-subject';
import {map} from 'rxjs/operators';
import {Branch} from '../models/company';
import {ForwardingFile, TrackingDetails, ForwardingTransportDocument} from '../models/ForwardingFile';
import {CurrencyService} from './currency.service';
import {CollectPrepaidService} from './collect-prepaid.service';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  private clearingFile: ClearingFile;
  private billsOfEntry: BillsOfEntry|ExportBillsOfEntry|ExBondBillsOfEntry;
  private transportDocument: TransportDocument|ExportTransportDocument|ForwardingTransportDocument;
  private container: Container;
  private supplierInvoice: SupplierInvoice;
  private exportFile: ExportFile;
  private exBondFile: ExBondFile;
  private loggedInUser: string;

  constructor(
    private companyService: CompanyService,
    private localAgentService: AgentService,
    private businessEntityService: BusinessEntityService,
    private customOfficeService: CustomOfficeService,
    private authorisationService: AuthorisationService,
    private currencyService: CurrencyService,
    private collectPrepaidService: CollectPrepaidService
  ) {
  }

  calcTotalOnFiles(file: ClearingFile|ExportFile, fileType: FileType) {
    let sum = 0;
    if (fileType === 'imports') {
      file.transportDocuments.forEach(t => {
        t.supplierInvoices.forEach(s => {
          sum += +s.totalGrossIncomeValue;
        });
      });
    } else {
      file.transportDocument.supplierInvoices.forEach(s => {
        sum += +s.totalGrossIncomeValue;
      });
    }
    if (sum) {
      return sum;
    }
    return 0;
  }

  calcTotalOnFilesForExports(file: ExportFile) {
    let sum = 0;
    if (file && file.transportDocument && file.transportDocument.supplierInvoices) {
      file.transportDocument.supplierInvoices.forEach(s => {
        sum += +s.totalGrossIncomeValue;
      });
    }
    if (sum) {
      return sum;
    }
    return 0;
  }

  buildClearingFile(transportMethod?: string, isQuotation?: boolean): ClearingFile {
    this.clearingFile = new ClearingFile();
    if (isQuotation) {
      this.clearingFile.quotation = true;
    }
    this.getBranch().subscribe(branch => this.clearingFile.branch = branch);
    this.clearingFile.reference = new Reference();
    this.getCurrentUser().subscribe(user => this.clearingFile.reference.channel = user);

    this.clearingFile.importer = new Importer();
    this.clearingFile.importer.address = new Address();

    this.clearingFile.debtor = new Debtor();
    this.clearingFile.debtor.address = new Address();

    this.clearingFile.supplier = new Supplier();
    this.clearingFile.supplier.address = new Address();

    this.clearingFile.localAgent = new LocalAgent();
    this.clearingFile.localAgent.address = new Address();
    this.clearingFile.localAgent.localAgentCreditors = new Array<LocalAgentCreditor>();
    this.clearingFile.localAgent.customsOfficeCreditors = new Array<LocalAgentCreditor>();
    this.clearingFile.localAgent.registrations = new Array<LocalAgentRegistrations>();
    this.clearingFile.customOffice = new CustomOffice();

    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = this.clearingFile.branch ? companies[0].branches
          .find(el => el.code === this.clearingFile.branch) : companies[0].branches[0];
        if (branch && branch.localAgentCode && branch.localAgentCode.trim() !== '') {
          this.localAgentService
            .findLocalAgentNameOrCodeStartsWith(branch.localAgentCode)
            .subscribe(data => {
              if (data && data.length) {
                this.clearingFile.localAgent = data[0];
              }
            });

          if (branch.customsOfficeDefaults.length) {
            const defaultCustomsOffice = branch.customsOfficeDefaults
              .find(value => value.declarationType === 'import' && value.transportMethod.method === transportMethod);
            if (defaultCustomsOffice) {
              this.customOfficeService.findAllCustomsOfficeStartWith(defaultCustomsOffice.customsOfficeCode).subscribe(data => {
                this.clearingFile.customOffice = data[0];
              });
            }
          }
        }
      }
    });

    this.clearingFile.incoTerm = new IncoTerm();

    this.clearingFile.assessmentDate = moment().format('DD/MM/YYYY');
    this.clearingFile.slaDate = moment().format('DD/MM/YYYY');
    this.clearingFile.clearingInstructions = [];
    this.clearingFile.clearingInstructions[0] = new ClearingInstruction();
    this.clearingFile.clearingInstructions[0].customsPurpose = new CustomsPurpose();
    this.clearingFile.clearingInstructions[0].customsPurpose.customsPurposeFile = new CustomsPurposeFile();
    this.clearingFile.clearingInstructions[0].transportMethod = new TransportMethod();
    this.clearingFile.clearingInstructions[0].ribOrRitportDetails = new PortDetail();
    this.clearingFile.transportDocuments = new Array<TransportDocument>();
    const deliveryInstruction = new ReportFile();
    deliveryInstruction.title = 'Delivery Instruction';
    const fileCover = new ReportFile();
    deliveryInstruction.title = 'File Cover';
    const provisionalPayment = new PaymentFile();
    provisionalPayment.title = 'Provisional Payment';
    const clearingInstruction = new ClearingInstructionFile();
    clearingInstruction.title = 'Clearing Instruction';
    this.clearingFile.reportFiles = [deliveryInstruction, fileCover];
    this.clearingFile.paymentFiles = [provisionalPayment];
    this.clearingFile.clearingInstructionFiles = [clearingInstruction];
    return this.clearingFile;
  }

  buildExportFile(transportMethod?: string) {
    this.exportFile = new ExportFile();
    this.getBranch().subscribe(branch => this.exportFile.branch = branch);
    this.exportFile.reference = new Reference();
    this.getCurrentUser().subscribe(user => this.exportFile.reference.channel = user);

    this.exportFile.exporter = new Exporter();
    this.exportFile.exporter.address = new Address();

    this.exportFile.debtor = new Debtor();
    this.exportFile.debtor.address = new Address();

    this.exportFile.exportConsignee = new ExportConsignee();
    this.exportFile.exportConsignee.address = new Address();

    this.exportFile.localAgent = new LocalAgent();
    this.exportFile.localAgent.address = new Address();
    this.exportFile.localAgent.localAgentCreditors = new Array<LocalAgentCreditor>();
    this.exportFile.localAgent.customsOfficeCreditors = new Array<LocalAgentCreditor>();
    this.exportFile.localAgent.registrations = new Array<LocalAgentRegistrations>();
    this.exportFile.customOffice = new CustomOffice();
    this.exportFile.transportDocument = <ExportTransportDocument>this.addTransportDocument('exports');
    if (transportMethod === 'AIR') {
      this.exportFile.transportDocument.validateMawbNo = true;
    }

    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = this.exportFile.branch ? companies[0].branches
          .find(el => el.code === this.exportFile.branch) : companies[0].branches[0];
        if (branch.localAgentCode && branch.localAgentCode.trim() !== '') {
          this.exportFile.localAgent = {code: branch.localAgentCode};
        }

        if (branch.exporterCode) {
          this.exportFile.exporter = {code: branch.exporterCode};
        }

        if (branch.customsOfficeDefaults) {
          const defaultCustomsOffice = branch.customsOfficeDefaults
            .find(value => value.declarationType === 'export' && value.transportMethod.method === transportMethod);
          if (defaultCustomsOffice) {
            this.customOfficeService.findAllCustomsOfficeStartWith(defaultCustomsOffice.customsOfficeCode).subscribe(data => {
              this.exportFile.customOffice = data[0];
            });
          }
        }
      }
    });

    this.exportFile.incoTerm = new IncoTerm();
    this.exportFile.assessmentDate = moment().format('DD/MM/YYYY');
    this.exportFile.slaDate = moment().format('DD/MM/YYYY');
    this.exportFile.clearingInstructions = [];
    this.exportFile.clearingInstructions[0] = new ClearingInstruction();
    this.exportFile.clearingInstructions[0].customsPurpose = new CustomsPurpose();
    this.exportFile.clearingInstructions[0].customsPurpose.customsPurposeFile = new CustomsPurposeFile();
    this.exportFile.clearingInstructions[0].transportMethod = new TransportMethod();
    return this.exportFile;
  }

  buildExBondFile(): ExBondFile {
    this.exBondFile = new ExBondFile();
    this.getBranch().subscribe(branch => this.exBondFile.branch = branch);
    this.exBondFile.reference = new Reference();
    this.getCurrentUser().subscribe(user => this.exBondFile.reference.channel = user);

    this.exBondFile.importer = new Importer();
    this.exBondFile.importer.address = new Address();

    this.exBondFile.debtor = new Debtor();
    this.exBondFile.debtor.address = new Address();

    this.exBondFile.localAgent = new LocalAgent();
    this.exBondFile.localAgent.address = new Address();
    this.exBondFile.localAgent.localAgentCreditors = new Array<LocalAgentCreditor>();
    this.exBondFile.localAgent.customsOfficeCreditors = new Array<LocalAgentCreditor>();
    this.exBondFile.localAgent.registrations = new Array<LocalAgentRegistrations>();

    this.exBondFile.warehouse = new Warehouse();
    this.exBondFile.warehouse.address = new Address();

    this.exBondFile.customOffice = new CustomOffice();

    this.exBondFile.assessmentDate = moment().format('DD/MM/YYYY');
    this.exBondFile.slaDate = moment().format('DD/MM/YYYY');
    this.exBondFile.clearingInstructions = [];
    this.exBondFile.clearingInstructions[0] = new ClearingInstruction();
    this.exBondFile.clearingInstructions[0].customsPurpose = new CustomsPurpose();
    this.exBondFile.clearingInstructions[0].customsPurpose.customsPurposeFile = new CustomsPurposeFile();
    this.exBondFile.clearingInstructions[0].transportMethod = new TransportMethod();
    this.exBondFile.marksAndNumbers = new Array<string>();
    this.exBondFile.endorsements = new Array<string>();
    return this.exBondFile;
  }

  addTransportDocument(type: FileType): TransportDocument|ExportTransportDocument|ForwardingTransportDocument {

    if (type === 'imports') {
      this.transportDocument = new TransportDocument();
      this.transportDocument.previousBOEDetails = new PreviousBoeDetails();
      this.transportDocument.previousBOEDetails.portTo = new PortTo();
      this.transportDocument.previousBOEDetails.customOffice = new CustomOffice();
    } else {
      if (type === 'exports') {
        this.transportDocument = new ExportTransportDocument();
      } else {
        this.transportDocument = new ForwardingTransportDocument();
      }
      this.transportDocument.exitPort = new PortDetail();
      this.transportDocument.notifyPartyAddress = new Address();
      this.transportDocument.coLoader = new LocalAgent();
      this.transportDocument.coLoader.address = new Address();
      this.transportDocument.overseasAgent = new OverseasAgent();
      this.transportDocument.overseasAgent.address = new Address();
      this.transportDocument.accountingInformation = new Information();
      this.transportDocument.handlingInformation = new Information();
    }

    this.transportDocument.airline = new Airline();
    this.transportDocument.freight = new Freight();
    this.transportDocument.freight.collectPrepaid = new CollectPrepaid();
    this.transportDocument.freight.currency = new Currency();
    this.transportDocument.freight.amount = 0;
    this.transportDocument.depotTerminal = new DepotTerminal();
    this.transportDocument.measures = new Measures();
    this.transportDocument.destinationPort = new PortDetail();
    this.transportDocument.finalDestinationPort = new PortDetail();
    this.transportDocument.departurePort = new PortDetail();
    this.transportDocument.docsIssuedAtPort = new PortDetail();
    this.transportDocument.dischargePort = new PortDetail();
    this.transportDocument.marksAndNumbers = new Array<string>();
    this.transportDocument.endorsements = new Array<string>();
    this.transportDocument.rateBasedCharges = new Array<RateBasedCharge>();
    this.transportDocument.valueBasedCharges = new Array<ValueBasedCharge>();
    this.transportDocument.supplierInvoices = new Array<SupplierInvoice>();
    this.transportDocument.oceanLineDetails = new OceanLineDetails();
    this.transportDocument.oceanLineDetails.oceanLine = new OceanLine();
    this.transportDocument.oceanLineDetails.carrierCode = new OceanLine();
    this.transportDocument.voyageDetails = new VoyageDetails();
    this.transportDocument.voyageDetails.customsRadioCallSign = new CustomsRadioCallSign();
    this.transportDocument.voyageDetails.remover = new Remover();
    this.transportDocument.containers = new Array<Container>();
    this.transportDocument.containerRunningTotals = new ContainerRunningTotals();
    this.transportDocument.straight = true;
    this.transportDocument.localForwarder = new LocalForwarder();
    this.transportDocument.forwardFile = new ForwardFile();
    this.companyService.getCompany().subscribe(companies => {
          if (companies && companies.length) {
            if (type === 'imports') {
              const branch: Branch = this.clearingFile.branch ? companies[0].branches
                            .find(el => el.code === this.clearingFile.branch) : companies[0].branches[0];

              if (branch.freightCurrency) {
                this.currencyService.findCurrencyStartWith(branch.freightCurrency).subscribe(data => {
                  this.transportDocument.freight.currency = data[0];
                });
              }
              if (branch.freightAmount) {
                this.transportDocument.freight.amount = branch.freightAmount;
              }
              if (branch.freightCollectPrepaid) {
                this.collectPrepaidService.findCollectPrepaidStartWith(branch.freightCollectPrepaid).subscribe(data => {
                  this.transportDocument.freight.collectPrepaid = data[0];
                });
              }
            } else {
              const branch: Branch = this.exportFile.branch ? companies[0].branches
                            .find(el => el.code === this.exportFile.branch) : companies[0].branches[0];

              if (branch.freightCurrency) {
                this.currencyService.findCurrencyStartWith(branch.freightCurrency).subscribe(data => {
                  this.transportDocument.freight.currency = data[0];
                });
              }
              if (branch.freightAmount) {
                this.transportDocument.freight.amount = branch.freightAmount;
              }
              if (branch.freightCollectPrepaid) {
                this.collectPrepaidService.findCollectPrepaidStartWith(branch.freightCollectPrepaid).subscribe(data => {
                  this.transportDocument.freight.collectPrepaid = data[0];
                });
              }
            }
          }
        });
    return this.transportDocument;
  }

  addContainer(): Container {
    this.container = new Container();
    this.container.containerType = new ContainerType();
    this.container.solas = new Solas();
    this.container.containerSpecification = new ContainerSpecification();
    this.container.containerSpecification.containerSize = new ContainerSize();
    this.container.sealDetails = new SealDetails();
    this.container.terminal = new DepotTerminal();
    this.container.depot = new DepotTerminal();
    return this.container;
  }

  addSupplierInvoice(selectedTransport): SupplierInvoice {
    this.supplierInvoice = new SupplierInvoice();
    this.supplierInvoice.supplier = new Supplier();
    this.supplierInvoice.supplier.address = new Address();
    this.supplierInvoice.currency = new Currency();
    this.supplierInvoice.defaultValues = new DefaultValues();
    this.supplierInvoice.defaultValues.country = new Country();
    this.supplierInvoice.defaultValues.purchaseTerm = new PurchaseTerm();
    this.supplierInvoice.userInterfaceDefaults = new UserInterfaceDefaults();
    this.supplierInvoice.runningTotals = new RunningTotals();
    this.supplierInvoice.lines = new Array<SupplierInvoiceLine>();
    this.supplierInvoice.defaultValues.country.tradeAgreements = new Array<TradeAgreement>();
    this.supplierInvoice.rateBasedCharges = new Array<RateBasedCharge>();
    this.supplierInvoice.valueBasedCharges = new Array<ValueBasedCharge>();
    this.supplierInvoice.sequence = selectedTransport.supplierInvoices.length + 1;
    this.supplierInvoice.apportionWeightOnLines = true;
    this.supplierInvoice.certificate = new Certificate();
    this.supplierInvoice.certificate.type = new CertificateType();
    return this.supplierInvoice;
  }

  addSupplierInvoiceLine(selectedInvoice: SupplierInvoice, selectedInvoiceLine: SupplierInvoiceLine): SupplierInvoiceLine {
    let invoiceLine: SupplierInvoiceLine;
    if (selectedInvoice.userInterfaceDefaults.autoRepeat && selectedInvoiceLine) {
      invoiceLine = {...selectedInvoiceLine};
    } else {
      invoiceLine = new SupplierInvoiceLine();
      invoiceLine.countryOfOrigin = {...selectedInvoice.defaultValues.country};
      invoiceLine.tradeAgreement = new TradeAgreement();
      invoiceLine.tariffCode = new TariffCode();
      invoiceLine.tariffCode.formula = new TariffFormula();
      invoiceLine.tariffCode.parameters = Array<TariffParameter>();
      invoiceLine.userInterfaceCheckbox = new UserInterfaceCheckbox();
      invoiceLine.discountPercent = 0;
      invoiceLine.discountValue = 0;
      invoiceLine.vdnPercent = 0;
      invoiceLine.additionalRebates = [];
      if (typeof (selectedInvoice.supplier.vdnMarkup) !== 'undefined') {
        invoiceLine.vdnPercent = selectedInvoice.supplier.vdnMarkup;
      }
      invoiceLine.permit = new Permit();
      invoiceLine.rooNumber = selectedInvoice.defaultValues.rooNumber;
      invoiceLine.previousBoeDetails = new PreviousBoeDetails();
    }

    if (selectedInvoice.userInterfaceDefaults.quickPart) {
      invoiceLine.userInterfaceCheckbox.quickPart = selectedInvoice.userInterfaceDefaults.quickPart;
    }
    if (selectedInvoice.userInterfaceDefaults.tariffMode) {
      invoiceLine.userInterfaceCheckbox.tariffMode = selectedInvoice.userInterfaceDefaults.tariffMode;
    }
    if (selectedInvoice.userInterfaceDefaults.autoClientOrderNumberClientRef) {
      invoiceLine.additionalClientReference = selectedInvoice.additionalClientReference;
    }
    if (selectedInvoice.userInterfaceDefaults.autoClientOrderNumberPart && selectedInvoiceLine) {
      invoiceLine.partNumber = selectedInvoiceLine.partNumber;
      invoiceLine.tariff = selectedInvoiceLine.tariff;
      invoiceLine.tariffCheckDigit = selectedInvoiceLine.tariffCheckDigit;
      invoiceLine.tariffDescription = selectedInvoiceLine.tariffDescription;
      invoiceLine.tariff = selectedInvoiceLine.tariff;
      invoiceLine.fullTariffDescription = selectedInvoiceLine.fullTariffDescription;
      invoiceLine.formula = selectedInvoiceLine.formula;
    }
    invoiceLine.lineNo = selectedInvoice.lines.length + 1;

    invoiceLine.ucrNumber = selectedInvoice.ucrNumber;
    if (selectedInvoice.ucr) {
      invoiceLine.ucrCreditTerms = selectedInvoice.ucr.paymentTerm.code;
      invoiceLine.ucrTransactionValue = selectedInvoice.ucr.transactionValue;
      invoiceLine.ucrTransactionValueCurrency = selectedInvoice.ucr.transactionCurrency.code;
    }
    return invoiceLine;
  }

  addValueBasedCharge(hasSundryCharge: HasSundryCharge) {
    let charge = new ValueBasedCharge();
    charge.currency = new Currency();
    charge.sundryCharge = new SundryCharge();
    const subject = new BehaviorSubject<ValueBasedCharge>(charge);
    subject.subscribe((data) => {
      charge = data;
    });
    subject.next(charge);
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = companies[0].branches[0];
        if (branch.defaultSundryChargeNonDutiable) {
          charge.nonDutiableValue = true;
          charge.nonDutiable = true;
        }
      }
    });
    hasSundryCharge.valueBasedCharges.push(charge);
    return hasSundryCharge.valueBasedCharges;
  }

  addRateBasedCharge(hasSundryCharge: HasSundryCharge) {
    let charge = new RateBasedCharge();
    charge.currency = new Currency();
    charge.sundryCharge = new SundryCharge();
    const subject = new BehaviorSubject<RateBasedCharge>(charge);
    subject.subscribe((data) => {
      charge = data;
    });
    subject.next(charge);
    hasSundryCharge.rateBasedCharges.push(charge);
    return hasSundryCharge.rateBasedCharges;
  }

  buildBillOfEntry(type: FileType): BillOfEntry|ExportBillOfEntry {
    if (type === 'imports') {
      this.billsOfEntry = new BillsOfEntry();
      this.billsOfEntry.boes[0] = new BillOfEntry();
      this.billsOfEntry.boes[0].importer = new Importer();
      this.billsOfEntry.boes[0].exporters = [];
      this.billsOfEntry.boes[0].lines = new Array<BillOfEntryLine>();
      this.billsOfEntry.boes[0].export = new Export();
    } else if (type === 'exports') {
      this.billsOfEntry = new ExportBillsOfEntry();
      this.billsOfEntry.boes[0] = new ExportBillOfEntry();
      this.billsOfEntry.boes[0].exporter = new Exporter();
      this.billsOfEntry.boes[0].lines = new Array<ExportBillOfEntryLine>();
      this.billsOfEntry.boes[0].receivingBank = new ReceivingBank();
    } else {
      this.billsOfEntry = new ExBondBillsOfEntry();
      this.billsOfEntry.boes[0] = new ExBondBillOfEntry();
      this.billsOfEntry.boes[0].importer = new Importer();
      this.billsOfEntry.boes[0].lines = new Array<ExBondBillOfEntryLine>();
    }

    this.billsOfEntry.boes[0].clearingInstruction = new ClearingInstruction();
    this.billsOfEntry.boes[0].markAndNumbers = [];
    this.billsOfEntry.boes[0].permits = [];
    this.billsOfEntry.boes[0].destination = new Destination();
    this.billsOfEntry.boes[0].permits[0] = new BillOfEntryPermit();
    this.billsOfEntry.boes[0].boeInvoiceDetails = new Array<BoEInvoiceDetails>();
    return this.billsOfEntry.boes[0];
  }

  buildForwardingFile(transportMethod?: string): ForwardingFile {
    const forwardingFile = new ForwardingFile();
    this.getBranch().subscribe(branch => forwardingFile.branch = branch);
    forwardingFile.transportMethodCode = transportMethod;
    // @ts-ignore
    forwardingFile.masterBill = new ForwardingTransportDocument();
    forwardingFile.masterBill.freight = new Freight();
    forwardingFile.masterBill.freight.collectPrepaid = new CollectPrepaid();
    forwardingFile.masterBill.freight.currency = new Currency();
    forwardingFile.masterBill.destinationPort = new PortDetail();
    forwardingFile.masterBill.departurePort = new PortDetail();
    forwardingFile.masterBill.docsIssuedAtPort = new PortDetail();
    forwardingFile.masterBill.dischargePort = new PortDetail();
    forwardingFile.masterBill.measures = new Measures();
    forwardingFile.masterBill.oceanLineDetails = new OceanLineDetails();
    forwardingFile.masterBill.oceanLineDetails.oceanLine = new OceanLine();
    forwardingFile.masterBill.oceanLineDetails.carrierCode = new OceanLine();
    forwardingFile.masterBill.voyageDetails = new VoyageDetails();
    forwardingFile.masterBill.voyageDetails.customsRadioCallSign = new CustomsRadioCallSign();
    // @ts-ignore
    forwardingFile.masterBill.runningTotals = new RunningTotals();
    forwardingFile.reference = new Reference();
    forwardingFile.warehouse = new Warehouse();
    forwardingFile.customOffice = new CustomOffice();
    forwardingFile.masterBill.trackingDetails = new TrackingDetails();
    return forwardingFile;
  }

  private getBranch(): Observable<string> {
    return this.authorisationService.getLoggedInUser().pipe(
      map(user => {
        if (user && user.attributes && user.attributes['branch']) {
          return user.attributes['branch'][0];
        }
      })
    );
  }

  private getCurrentUser(): Observable<string> {
    return this.authorisationService.getLoggedInUser().pipe(
      map(user => {
       if (user) {
         if (user.firstName && user.lastName) {
           return user.firstName + " " + user.lastName;
         } else {
           return user.name;
         }
       } else {
          return "";
       }
      })
    );
  }

  createShippingOrder(file: File, billOfEntry: AbstractBoE) {
    file.shippingOrder = new ShippingOrder();
    let transportDocument;
    if (file.type === 'ExportFile') {
      file.shippingOrder.exporter = file.exporter.name;
      transportDocument = file.transportDocument;
      file.shippingOrder.localPort = transportDocument.departurePort.name;
      file.shippingOrder.foreignPort = transportDocument.destinationPort.name;
    } else {
      file.shippingOrder.consignee = file.importer.name;
      transportDocument = file.transportDocuments[0];
      file.shippingOrder.localPort = transportDocument.destinationPort.name;
      file.shippingOrder.foreignPort = transportDocument.departurePort.name;
    }
    file.shippingOrder.vesselName = transportDocument.voyageDetails.customsRadioCallSign.vesselName;
    file.shippingOrder.voyageNumber = transportDocument.voyageDetails.voyageNo;
    file.shippingOrder.billOfLading = transportDocument.mawbNo;
    file.shippingOrder.transportMethodCode = 'ROA';
    file.shippingOrder.marksAndNumbers = transportDocument.marksAndNumbers;

    if (transportDocument.containers && transportDocument.containers.length) {
      file.shippingOrder.terminal = transportDocument.containers[0].terminal.name;
    }

    this.authorisationService.getLoggedInUser().subscribe(user => {
      file.shippingOrder.authorisedSignatory = user.name;
    });

    file.shippingOrder.boeNumber = billOfEntry.mrn;
    file.shippingOrder.boeDate = billOfEntry.releaseDate;

    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = file.branch ? companies[0].branches
          .find(el => el.code === file.branch) : companies[0].branches[0];
        if (branch) {
          file.shippingOrder.accountNumber = file.type === 'ClearingFile' ? branch.landingOrderAccountNo : branch.shippingOrderAccountNo;
        }
      }
    });
  }
}





