<div class=" ui-md-12 ui-g-12 base-text clearing-instruction-file print">
  <div class="ui-md-6 ui-g-6 title text-uppercase align-center">{{clearingInstructionFile.title}}</div>
  <div class="ui-g ui-md-6 ui-g-6 ui-g-nopad">
    <div class="ui-md-12 ui-g-12 align-center">
      <img [src]="logo" id="logo">
      <div class="ui-g-12 ui-md-12 text-uppercase">
        {{companyName}}<br>
        <div [innerHTML]="companyAddress"></div>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span> Importer:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top">
            <input id="importerName" [(ngModel)]="clearingInstructionFile.importerName" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top">
            <span> Tel No:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top border-right">
            <input id="telNumber" [(ngModel)]="clearingInstructionFile.telNumber" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span> Address:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top">
            <input id="importerAddress" [(ngModel)]="clearingInstructionFile.importerAddress" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top">
            <span> Contact Person:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top border-right">
            <input id="contactPerson" [(ngModel)]="clearingInstructionFile.contactPerson" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span> Importer Code:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top">
            <input id="importerCode" [(ngModel)]="clearingInstructionFile.importerCode" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span *ngIf="transportMethod==='SEA'"> Vessel:</span>
            <span *ngIf="transportMethod==='AIR'"> Airline:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top border-right">
            <input id="vesselOrAirlineName" [(ngModel)]="clearingInstructionFile.vesselOrAirlineName" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span> VAT Number:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top">
            <input id="importerVatNo" [(ngModel)]="clearingInstructionFile.importerVatNo" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span *ngIf="transportMethod==='SEA'"> Bill Of Lading:</span>
            <span *ngIf="transportMethod==='AIR'"> Air Waybill:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top border-right">
            <input id="bolOrAwb" [(ngModel)]="clearingInstructionFile.bolOrAwb" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span> Origin:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top">
            <span> South Africa</span>
          </div>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span *ngIf="transportMethod==='SEA'"> Radio Call Sign:</span>
            <span *ngIf="transportMethod==='AIR'"> Flight No:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top border-right">
            <input id="callSignOrFlightNo" [(ngModel)]="clearingInstructionFile.callSignOrFlightNo" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span> Destination:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top">
            <span> South Africa</span>
          </div>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left">
            <span *ngIf="transportMethod==='SEA'"> Container No:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top border-right">
            <input *ngIf="transportMethod==='SEA'" id="containerNo" [(ngModel)]="clearingInstructionFile.containerNo" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-top">
          <span> Enclosure (Mark as necessary):</span>
        </div>
      </div>

      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="ui-md-4 ui-g-4 ui-g-nopad">
          <div class="pp-small-rect border-top border-left border-bottom">
            <span> No. of pieces:</span>
          </div>
        </div>
        <div class="ui-md-8 ui-g-8 ui-g-nopad">
          <div class="pp-small-rect border-top border-right border-bottom">
            <input id="noOfPackages" [(ngModel)]="clearingInstructionFile.noOfPackages" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.negotiableBolOrAwb"></p-checkbox>
        <span *ngIf="transportMethod==='SEA'">  Negotiable Bill of Lading    </span>
        <span *ngIf="transportMethod==='AIR'">  Negotiable Air Waybill    </span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.formDa59"></p-checkbox>
        <span>  Form DA59    </span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.copyBolOrAwb"></p-checkbox>
        <span *ngIf="transportMethod==='SEA'">  Copy Bill of Lading    </span>
        <span *ngIf="transportMethod==='AIR'">  Copy Air Waybill    </span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.packingList"></p-checkbox>
        <span>  Packing List    </span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <span>  Destination Code:    </span>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <input id="destinationCode" [(ngModel)]="clearingInstructionFile.destinationCode" class="ui-g-4 ui-md-4" type="text" pInputText/>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.arrivalNotification"></p-checkbox>
        <span>  Arrival Notification    </span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.bankPop"></p-checkbox>
        <span>  Bank Proof of Payment    </span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.commercialInvoices"></p-checkbox>
        <span>  Commercial Invoices    </span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.descrLiterature"></p-checkbox>
        <span>  Descriptive Literature    </span>
      </div>
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <span>  Place of Clearance:    </span>
      </div>
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <input id="placeOfClearance" [(ngModel)]="clearingInstructionFile.placeOfClearance" class="ui-g-4 ui-md-4" type="text" pInputText/>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-4 ui-g-4 ui-g-nopad">
      <div class="ui-md-4 ui-g-4 ui-g-nopad">
        <span>  Other (Specify):    </span>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <input id="enclosureOtherSpecify" [(ngModel)]="clearingInstructionFile.enclosureOtherSpecify" class="ui-g-6 ui-md-6" type="text" pInputText/>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="pp-small-rect h2-text border-bottom align-center">
      <span>  CUSTOMS PROCEDURE CODE:</span>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="pp-small-rect">
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-top border-left border-right border-bottom">
          <span>  A11-00    </span>
        </div>
      </div>
      <div class="ui-md-1 ui-g-1 ui-g-nopad">
        <div class="pp-small-rect">
        </div>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.dutyPaid"></p-checkbox>
          <span>  DUTY PAID    </span>
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.sch3ItemFlag"></p-checkbox>
        <span>  SCH 3 ITEM NO.307.06    </span>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-bottom">
          <span>  A11-40    </span>
        </div>
      </div>
      <div class="ui-md-1 ui-g-1 ui-g-nopad">
        <div class="pp-small-rect">
        </div>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.dutyPaidEb"></p-checkbox>
          <span>  DUTY PAID EX BOND    </span>
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.industrialAndUseFlag"></p-checkbox>
        <span>  INDUSTRIAL AND USE    </span>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-bottom">
          <span>  E40-00    </span>
        </div>
      </div>
      <div class="ui-md-1 ui-g-1 ui-g-nopad">
        <div class="pp-small-rect">
        </div>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.warehouseFlag"></p-checkbox>
          <span>  WAREHOUSE    </span>
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.sch4ItemFlag"></p-checkbox>
        <span>  SCH 4 ITEM NO.--------    </span>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-bottom">
          <span>  B21-00    </span>
        </div>
      </div>
      <div class="ui-md-1 ui-g-1 ui-g-nopad">
        <div class="pp-small-rect">
        </div>
      </div>
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.ritFlag"></p-checkbox>
          <span>  RIT    </span>
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="pp-small-rect">
        <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.warehouseForExportFlag"></p-checkbox>
        <span>  WAREHOUSE FOR EXPORT    </span>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="pp-small-rect">
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-9 ui-g-9 ui-g-nopad">
        <div class="pp-small-rect">
          <span> Customs Valuation Codes</span>
        </div>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-8 ui-g-8 ui-g-nopad">
        <div class="pp-small-rect">
          <span> Method</span>
        </div>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <span>  R   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationCodeR"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-right border-top border-bottom align-center">
          <span>  N   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationCodeN"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-right border-top border-bottom align-center">
          <span>  E   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationCodeE"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="ui-md-2 ui-g-2 ui-g-nopad">
      <div class="pp-small-rect">
      </div>
    </div>

    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <span>  1   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationMethod1"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-right border-top border-bottom align-center">
          <span>  2   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationMethod2"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-right border-top border-bottom align-center">
          <span>  3   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationMethod3"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-right border-top border-bottom align-center">
          <span>  4   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationMethod4"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-right border-top border-bottom align-center">
          <span>  5   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationMethod5"></p-checkbox>
        </div>
      </div>
    </div>

    <div class="ui-md-1 ui-g-1 ui-g-nopad">
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-right border-top border-bottom align-center">
          <span>  6   </span>
        </div>
      </div>
      <div class="ui-md-6 ui-g-6 ui-g-nopad">
        <div class="pp-small-rect border-left border-right border-top border-bottom align-center">
          <p-checkbox [binary]="true" [(ngModel)]="clearingInstructionFile.valuationMethod6"></p-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="pp-small-rect">
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
      <span>  Invoice No:    </span>
      <input id="invoiceNumber" [(ngModel)]="clearingInstructionFile.invoiceNo" type="text" pInputText/>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <span>  Itac Permit:    </span>
    <input id="permitNumber" [(ngModel)]="clearingInstructionFile.permitNo" type="text" pInputText/>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <span>  Tariff Heading:    </span>
    <input id="tariffHeading" [(ngModel)]="clearingInstructionFile.tariffHeading" type="text" pInputText/>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <span>  Eur1:    </span>
    <input id="rooNumber" [(ngModel)]="clearingInstructionFile.rooNumber" type="text" pInputText/>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="pp-small-rect">
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="pp-small-rect small-text">
        <div class="ui-md-1 ui-g-1 ui-g-nopad"> I</div>
        <input id="signingParty" [(ngModel)]="clearingInstructionFile.signingParty" class="ui-md-4 ui-g-4 dotted" type="text" pInputText/>
        <div class="ui-md-2 ui-g-2 ui-g-nopad"> request</div>
        <input id="businessParty" [(ngModel)]="clearingInstructionFile.businessParty" class="ui-md-5 ui-g-5 dotted" type="text" pInputText/>
      </div>
    </div>

    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="pp-small-rect small-text">
        <span>  to clear and deliver these goods under the above-mentioned condition.</span>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="med-rect small-text">
      <span>  I further declare that no other clearing instruction has been given to any person to affect clearances on my behalf. This signature certifies that the signatory is an employee of the company.</span>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="signage-rect">
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <input id="signature" class="ui-md-6 ui-g-6 dotted" type="text" readonly pInputText/>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <input id="initials" [(ngModel)]="clearingInstructionFile.initials" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <input id="surname" [(ngModel)]="clearingInstructionFile.surname" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <input id="capacity" [(ngModel)]="clearingInstructionFile.capacity" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
      </div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad">
        <input id="date" [(ngModel)]="clearingInstructionFile.date" class="ui-md-6 ui-g-6 dotted" type="text" pInputText/>
      </div>
    </div>
  </div>

  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="pp-small-rect">
      <div class="ui-md-2 ui-g-2 ui-g-nopad"> Signature</div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad"> Initials</div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad"> Surname</div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad"> Capacity</div>
      <div class="ui-md-2 ui-g-2 ui-g-nopad"> Date  (CCYYMMDD)</div>
    </div>
  </div>
</div>
