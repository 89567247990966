<div class=" ui-md-12 ui-g-12 report-file print">
  <div class="ui-md-12 ui-g-12 title text-uppercase">{{reportFile.title}}</div>
  <div class="ui-g ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="ui-md-12 ui-g-12 ui-g-nopad">
        <div class="big-rect border-top border-right border-left">
          <div class="box"></div>
          <span><strong>Importer</strong></span>
          <div class="ui-md-12 ui-g-12">
            {{clearingFile.importer.name}}<br>
            <span *ngIf="clearingFile.importer.address.line1">{{clearingFile.importer.address.line1}}</span><br>
            <span *ngIf="clearingFile.importer.address.line2">{{clearingFile.importer.address.line2}}</span><br>
            <span *ngIf="clearingFile.importer.address.line3">{{clearingFile.importer.address.line3}}</span><br>
            <span *ngIf="clearingFile.importer.address.line4">{{clearingFile.importer.address.line4}}</span><br>
            <span *ngIf="clearingFile.importer.address.zipCode">{{clearingFile.importer.address.zipCode}}</span><br>
          </div>
          <div class="ui-md-12 ui-g-12 footer">
            <div class="ui-md-6 ui-g-6 ui-g-nopad">
              <span class="ui-float-label no-print">
              <input id="phone" [(ngModel)]="reportFile.importerPhoneNo" class="ui-g-10 ui-md-10" type="text" pInputText/>
              <label for="phone">Phone</label>
            </span>
              <span class="ui-float-label print-label"> Phone: {{reportFile.importerPhoneNo}}</span>
            </div>
            <div class="ui-md-6 ui-g-6 ui-g-nopad">
              <span class="ui-float-label no-print">
              <input id="fax" [(ngModel)]="reportFile.importerFaxNo" class="ui-g-10 ui-md-10" type="text" pInputText/>
              <label for="fax">Fax</label>
            </span>
              <span class="ui-float-label print-label"> Fax: {{reportFile.importerFaxNo}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="big-rect ui-md-12 ui-g-12 ui-g-nopad border-top border-right border-left">
        <div class="box"></div>
        <span><strong>Delivery Address</strong></span>
        <div *ngIf="reportFile && reportFile.deliveryAddress" class="ui-md-12 ui-g-12 no-print">
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="reportFile.deliveryAddress.line1" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="reportFile.deliveryAddress.line2" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="reportFile.deliveryAddress.line3" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="reportFile.deliveryAddress.line4" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
          <div class="ui-g-12 ui-g-nopad">
            <input [(ngModel)]="reportFile.deliveryAddress.zipCode" class="ui-g-10 ui-md-10" type="text" pInputText/>
          </div>
        </div>
        <div class="ui-md-12 ui-g-12 print-label">
          <span *ngIf="reportFile?.deliveryAddress?.line1">{{reportFile.deliveryAddress.line1}}</span><br>
          <span *ngIf="reportFile?.deliveryAddress?.line2">{{reportFile.deliveryAddress.line2}}</span><br>
          <span *ngIf="reportFile?.deliveryAddress?.line3">{{reportFile.deliveryAddress.line3}}</span><br>
          <span *ngIf="reportFile?.deliveryAddress?.line4">{{reportFile.deliveryAddress.line4}}</span><br>
          <span *ngIf="reportFile?.deliveryAddress?.zipCode">{{reportFile.deliveryAddress.zipCode}}</span><br>
        </div>
        <div class="ui-md-12 ui-g-12 footer">
            <span class="ui-float-label no-print">
              <input id="attention" [(ngModel)]="reportFile.attention" class="ui-g-10 ui-md-10" type="text" pInputText/>
              <label for="attention">Attention</label>
            </span>
          <span class="ui-float-label print-label"> Attention: <span class="text-uppercase">{{reportFile.attention}}</span></span>
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 align-center">
      <img [src]="logo" id="logo">
      <div class="ui-g-12 ui-md-12 text-uppercase">
        {{companyName}}<br>
        <div [innerHTML]="companyAddress | multilineAddress"></div>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="big-rect border-top border-right border-left">
        <div class="box"></div>
        <span><strong>Supplier</strong></span>
        <div class="ui-md-12 ui-g-12 text-uppercase">
          {{supplier.name}}<br>
          <span *ngIf="supplier?.address?.line1">{{supplier.address.line1}}</span><br>
          <span *ngIf="supplier?.address?.line2">{{supplier.address.line2}}</span><br>
          <span *ngIf="supplier?.address?.line3">{{supplier.address.line3}}</span><br>
          <span *ngIf="supplier?.address?.line4">{{supplier.address.line4}}</span><br>
          <span *ngIf="supplier?.address?.zipCode">{{supplier.address.zipCode}}</span><br>
        </div>
        <div *ngIf="transportMethod==='SEA'" class="ui-md-12 ui-g-12 footer">
          <div class="ui-md-3 ui-g-3">
            Cont. Dep.:
          </div>
          <div class="ui-md-9 ui-g-9">
            {{transportDocument.containers[0].depot && transportDocument.containers[0].depot.code ? transportDocument.containers[0].depot.name : transportDocument.containers[0].terminal.name}}
          </div>
        </div>
      </div>
    </div>
    <div class="ui-md-6 ui-g-6 ui-g-nopad">
      <div class="big-rect border-top border-right">
        <div class="box"></div>
        <div *ngIf="transportMethod==='SEA'" class="footer ui-g-offset-1 ui-g-4 ui-md-4">Est. Delivery Date: {{transportDocument.arrivalDate}}</div>
        <div *ngIf="transportMethod==='AIR'" class="footer ui-g-offset-1 ui-g-4 ui-md-4">Depot: {{transportDocument.depotTerminal.code}}</div>
        <div class="footer ui-g-offset-5 ui-g-4 ui-md-4">File Number: {{clearingFile.clearingFileNumber}}</div>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 ui-g-nopad">
    <div *ngIf="transportMethod==='SEA'" class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right border-left">
        <span><strong>Container Number</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.containers[0].containerNo}}
        </div>
      </div>
    </div>
    <div *ngIf="transportMethod==='AIR'" class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right border-left">
        <span><strong>File Reference</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{clearingFile.reference.referenceFileNo}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right">
        <span><strong>Importer Reference</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.clientRefNo}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right">
        <span *ngIf="transportMethod==='SEA'"><strong>E.T.A</strong></span>
        <span *ngIf="transportMethod==='AIR'"><strong>Flight Date</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.departureDate}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>File Reference</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{clearingFile.reference.referenceFileNo}}
        </div>
      </div>
      <div *ngIf="transportMethod==='AIR'" class="small-rect border-top border-right">
        <span><strong>Flight</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.flight}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right border-left">
        <span><strong>Ocean Vessel</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.voyageDetails.customsRadioCallSign.vesselName}}
        </div>
      </div>
      <div *ngIf="transportMethod==='AIR'" class="small-rect border-top border-right border-left">
        <span><strong>House Airwaybill</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.hawbNo}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>Port Of Loading</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.departurePort.name}}
        </div>
      </div>
      <div *ngIf="transportMethod==='AIR'" class="small-rect border-top border-right">
        <span><strong>Master Airwaybill No.</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.mawbNo}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>House Bill Of Lading</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.hawbNo}}
        </div>
      </div>
      <div *ngIf="transportMethod==='AIR'" class="small-rect border-top border-right">
        <span><strong>Master Airwaybill Date</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.mawbDate}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right">
        <span><strong>Ocean Bill Of Lading</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.mawbNo}}
        </div>
      </div>
      <div *ngIf="transportMethod==='AIR'" class="small-rect border-top border-right">
        <span><strong>Port of Loading</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.departurePort.name}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div class="small-rect border-top border-right border-left border-bottom">
        <span><strong>Port of Entry</strong></span>
        <div *ngIf="transportMethod==='SEA'" class="ui-md-12 ui-g-12">
          {{transportDocument.dischargePort.name}}
        </div>
        <div *ngIf="transportMethod==='AIR'" class="ui-md-12 ui-g-12">
          {{transportDocument.destinationPort.name}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right border-bottom">
        <span><strong>Shipment Type</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.containers[0].containerType.code}}
        </div>
      </div>
      <div *ngIf="transportMethod==='AIR'" class="small-rect border-top border-right border-bottom">
        <span><strong>Country of Export</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.departurePort.countryCode}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right border-bottom">
        <span><strong>Date of Shipment</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.mawbDate}}
        </div>
      </div>
      <div *ngIf="transportMethod==='AIR'" class="small-rect border-top border-right border-bottom">
        <span><strong>Bill of Entry No.</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{boe.mrn}}
        </div>
      </div>
    </div>
    <div class="ui-md-3 ui-g-3 ui-g-nopad">
      <div *ngIf="transportMethod==='SEA'" class="small-rect border-top border-right border-bottom">
        <span><strong>Country of Export</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{transportDocument.departurePort.countryCode}}
        </div>
      </div>
      <div *ngIf="transportMethod==='AIR'" class="small-rect border-top border-right border-bottom">
        <span><strong>Our Invoice No.</strong></span>
        <div class="ui-md-12 ui-g-12">
          {{invoiceNo}}
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g-12 ui-md-12 ui-g-nopad marks-and-numbers border-left border-right border-bottom">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-md-3 ui-g-3 border-bottom ui-g-nopad">
        <strong><i>Marks and Numbers</i></strong>
      </div>
      <div class="ui-md-3 ui-g-3 border-bottom ui-g-nopad">
        <strong><i>Number & Kind of Packages</i></strong>
      </div>
      <div class="ui-md-3 ui-g-3 border-bottom ui-g-nopad">
        <strong><i>Description of Goods</i></strong>
      </div>
      <div class="ui-md-3 ui-g-3 border-bottom ui-g-nopad">
        <div class="ui-md-6 ui-g-6 ui-g-nopad">
          <strong><i>Gross Weight</i></strong>
        </div>
        <div class="ui-md-6 ui-g-6 ui-g-nopad">
          <strong><i>Measurement</i></strong>
        </div>
      </div>
      <div class="ui-md-12 ui-g-12 containers">
        <div *ngIf="transportMethod==='AIR'" class="ui-md-3 ui-g-3 ui-g-offset-3">
          {{transportDocument.measures.numberOfPackages}}
        </div>
        <div class="ui-md-3 ui-g-3" [ngClass]="{'ui-g-offset-3':transportMethod==='AIR','ui-g-offset-9':transportMethod==='SEA'}">
          <div class="ui-md-6 ui-g-6 ui-g-nopad">
            {{transportDocument.measures.grossWeight}} kg
          </div>
          <div class="ui-md-6 ui-g-6 ui-g-nopad">
            {{transportDocument.measures.volume}} m<sup>3</sup>
          </div>
        </div>
        <div *ngIf="transportMethod==='SEA'" class="ui-md-6 ui-g-6">
          <ng-container *ngFor="let container of transportDocument.containers">
            <div class="ui-md-6 ui-g-6 ui-g-nopad">
              <div class="ui-md-8 ui-g-8 ui-g-nopad">
                {{container.containerNo}}
              </div>
              <div class="ui-md-4 ui-g-4 ui-g-nopad">
                {{container.containerType.code}}
              </div>
            </div>
            <div class="ui-md-6 ui-g-6 ui-g-nopad">
              {{container.noOfPackages}}
            </div>
          </ng-container>
        </div>
        <div *ngIf="transportMethod==='AIR'" class="ui-md-6 ui-g-6">
          <div class="ui-md-6 ui-g-6 ui-g-nopad">
            REFERENCE NUMBERS<br>
            {{transportDocument.clientRefNo}}
          </div>
        </div>
        <div class="ui-md-12 ui-g-12 footer">
          <div class="ui-g">
            <div class="ui-md-5 ui-g-5">
              <span class="ui-float-label no-print">
              <input id="driver" [(ngModel)]="reportFile.driver" class="ui-g-10 ui-md-10" type="text" pInputText/>
              <label for="driver">Driver</label>
            </span>
              <span class="ui-float-label print-label"> <strong>Driver:</strong> <span class="dotted">{{reportFile.driver}}</span></span>
            </div>
            <div class="ui-md-5 ui-g-5">
              <div class="ui-md-4 ui-g-4 ui-g-nopad"><strong>Signature:</strong></div>
              <div class="ui-md-6 ui-g-6 dotted"></div>
            </div>
            <div class="ui-md-6 ui-g-6">
              <span class="ui-float-label no-print">
              <input id="vehicleRegNo" [(ngModel)]="reportFile.vehicleRegNo" class="ui-g-10 ui-md-10" type="text" pInputText/>
              <label for="vehicleRegNo">Vehicle Reg No</label>
            </span>
              <span class="ui-float-label print-label"> <strong>Vehicle Reg No:</strong> <span class="dotted">{{reportFile.vehicleRegNo}}</span></span>
            </div>
            <div class="ui-md-12 ui-g-12">
              No responsibility for damage will be accepted unless the delivery note is endorsed accordingly.
            </div>
            <div class="ui-md-4 ui-g-4">
              <div class="ui-md-4 ui-g-4 ui-g-nopad"><strong>Date/Time:</strong></div>
              <div class="ui-md-6 ui-g-6 dotted"></div>
            </div>
            <div class="ui-md-12 ui-g-12 ui-g-nopad"></div>
            <div class="ui-md-5 ui-g-5">
              <div class="ui-md-4 ui-g-4 ui-g-nopad"><strong>Received By:</strong></div>
              <div class="ui-md-8 ui-g-8 dotted"></div>
            </div>
            <div class="ui-md-5 ui-g-5">
              <div class="ui-md-4 ui-g-4 ui-g-nopad"><strong>Signature:</strong></div>
              <div class="ui-md-8 ui-g-8 dotted"></div>
            </div>
            <div class="ui-md-12 ui-g-12">
              Indent No.: {{transportDocument.clientRefNo}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-md-12 ui-g-12 text-uppercase">
    Description of Goods: {{clearingFile.shippingDescription}}
  </div>
  <div class="ui-md-12 ui-g-12">
    <div class="ui-md-4 ui-g-4 ui-g-nopad text-uppercase">
      Inco Terms: {{clearingFile.incoTerm.incoTermDesc}}
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad text-uppercase">
      Date: {{getDate()}}
    </div>
    <div class="ui-md-4 ui-g-4 ui-g-nopad text-uppercase">
      Time: {{getTime()}}
    </div>
  </div>
</div>
