/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./header/manifest-header.component.ngfactory";
import * as i2 from "./header/manifest-header.component";
import * as i3 from "../../digi-common/services/manifest.service";
import * as i4 from "../../digi-common/services/cuscar-type.service";
import * as i5 from "../../digi-common/services/notification.service";
import * as i6 from "./register/manifest-register.component.ngfactory";
import * as i7 from "./register/manifest-register.component";
import * as i8 from "../../digi-common/services/crew-member.service";
import * as i9 from "../../digi-common/services/transporter.service";
import * as i10 from "../../digi-common/services/exports-bill-of-entry.service";
import * as i11 from "../../../../node_modules/primeng/components/tabview/tabview.ngfactory";
import * as i12 from "primeng/components/tabview/tabview";
import * as i13 from "primeng/components/common/shared";
import * as i14 from "./waybills/waybills.component.ngfactory";
import * as i15 from "./waybills/waybills.component";
import * as i16 from "../../digi-common/services/company.service";
import * as i17 from "../../digi-common/services/pack-type.service";
import * as i18 from "./manifest-edi/manifest-edi.component.ngfactory";
import * as i19 from "./manifest-edi/manifest-edi.component";
import * as i20 from "../../digi-common/services/send-edi-to-sars.service";
import * as i21 from "../../../../node_modules/primeng/components/steps/steps.ngfactory";
import * as i22 from "primeng/components/steps/steps";
import * as i23 from "@angular/common";
import * as i24 from "./manifest.component";
import * as i25 from "@angular/router";
import * as i26 from "../../digi-common/services/agent.service";
var styles_ManifestComponent = ["\n    .ui-steps-item {\n      width: 20%\n    }\n      .color-white {\n          color: white !important;\n      }\n\n      ::-webkit-input-placeholder {\n          color: white;\n      }\n\n      :-ms-input-placeholder {\n          color: white;\n      }\n\n      ::placeholder {\n          color: white;\n      }\n\n      .page {\n          border: 9px solid transparent !important;\n          background-clip: content-box !important;\n          -o-border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;\n          border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAA6UlEQVR4Xl2Pi2rEMAwE16fm1f7/r14v7w4rI0IzLAF7hLxNevBSEMEF5+OilNCsRd8ZMyn+a4NmsOT8WJw1lFbSYgGFzF2bLFoLjTClWjKKGRWpDYAGXUnZ4uhbBUzF3Oe/GG/ue2fn4GgsyXhNgysV2JnrhKEMg4fEZcALmiKbNhBBRFpSyDOj1G4QOVly6O1FV54ZZq8OVygrciDt6JazRgi1ljTPH0gbrPmHPXAbCiDd4GawIjip1TPh9tt2sz24qaCjr/jAb/GBFTbq9KZ7Ke/Cqt8nayUikZKsWZK7Fe6bg5dOUt8fZHWG2BHc+6EAAAAASUVORK5CYII=) 9 9 repeat !important;\n      }"];
var RenderType_ManifestComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ManifestComponent, data: {} });
export { RenderType_ManifestComponent as RenderType_ManifestComponent };
function View_ManifestComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "digi-header", [], null, [[null, "onUpdateRoadManifest"], [null, "onCreateNew"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpdateRoadManifest" === en)) {
        var pd_0 = (_co.updateRoadManifest($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCreateNew" === en)) {
        var pd_1 = (_co.createNewRoadManifest() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ManifestHeaderComponent_0, i1.RenderType_ManifestHeaderComponent)), i0.ɵdid(2, 770048, null, 0, i2.ManifestHeaderComponent, [i3.ManifestService, i4.CuscarTypeService, i5.NotificationService], { roadManifest: [0, "roadManifest"] }, { onUpdateRoadManifest: "onUpdateRoadManifest", onCreateNew: "onCreateNew" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "digi-manifest-register", [], null, null, null, i6.View_ManifestRegisterComponent_0, i6.RenderType_ManifestRegisterComponent)), i0.ɵdid(4, 770048, null, 0, i7.ManifestRegisterComponent, [i8.CrewMemberService, i5.NotificationService, i9.TransporterService, i4.CuscarTypeService, i10.ExportsBillOfEntryService, i3.ManifestService], { roadManifest: [0, "roadManifest"], selectedCrewMember: [1, "selectedCrewMember"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roadManifest; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.roadManifest; var currVal_2 = _co.selectedCrewMember; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_ManifestComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "p-tabView", [["orientation", "left"], ["styleClass", "stepMenu"]], null, null, null, i11.View_TabView_0, i11.RenderType_TabView)), i0.ɵdid(3, 1097728, null, 1, i12.TabView, [i0.ElementRef], { orientation: [0, "orientation"], styleClass: [1, "styleClass"] }, null), i0.ɵqud(603979776, 1, { tabPanels: 1 }), (_l()(), i0.ɵeld(5, 16777216, null, 0, 4, "p-tabPanel", [["header", "Register"]], null, null, null, i11.View_TabPanel_0, i11.RenderType_TabPanel)), i0.ɵdid(6, 1228800, [[1, 4]], 1, i12.TabPanel, [i0.ViewContainerRef], { header: [0, "header"], selected: [1, "selected"] }, null), i0.ɵqud(603979776, 2, { templates: 1 }), (_l()(), i0.ɵand(0, null, 0, 1, null, View_ManifestComponent_2)), i0.ɵdid(9, 16384, [[2, 4]], 0, i13.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "left"; var currVal_1 = "stepMenu"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "Register"; var currVal_3 = true; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = "content"; _ck(_v, 9, 0, currVal_4); }, null); }
function View_ManifestComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "digi-waybills", [], null, [[null, "onUpdateRoadManifest"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpdateRoadManifest" === en)) {
        var pd_0 = (_co.updateRoadManifest($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_WaybillsComponent_0, i14.RenderType_WaybillsComponent)), i0.ɵdid(2, 770048, null, 0, i15.WaybillsComponent, [i10.ExportsBillOfEntryService, i3.ManifestService, i16.CompanyService, i17.PackTypeService], { roadManifest: [0, "roadManifest"] }, { onUpdateRoadManifest: "onUpdateRoadManifest" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roadManifest; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ManifestComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "digi-header", [], null, [[null, "onUpdateRoadManifest"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpdateRoadManifest" === en)) {
        var pd_0 = (_co.updateRoadManifest($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ManifestHeaderComponent_0, i1.RenderType_ManifestHeaderComponent)), i0.ɵdid(2, 770048, null, 0, i2.ManifestHeaderComponent, [i3.ManifestService, i4.CuscarTypeService, i5.NotificationService], { roadManifest: [0, "roadManifest"] }, { onUpdateRoadManifest: "onUpdateRoadManifest" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "digi-manifest-edi", [], null, [[null, "updateRoadManifest"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateRoadManifest" === en)) {
        var pd_0 = (_co.updateRoadManifest($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_ManifestEdiComponent_0, i18.RenderType_ManifestEdiComponent)), i0.ɵdid(4, 4964352, null, 0, i19.ManifestEdiComponent, [i3.ManifestService, i5.NotificationService, i20.SendEdiToSarsService, i4.CuscarTypeService], { roadManifest: [0, "roadManifest"], view: [1, "view"] }, { updateRoadManifest: "updateRoadManifest" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roadManifest; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.roadManifest; var currVal_2 = "review"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_ManifestComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "digi-header", [], null, [[null, "onUpdateRoadManifest"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpdateRoadManifest" === en)) {
        var pd_0 = (_co.updateRoadManifest($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ManifestHeaderComponent_0, i1.RenderType_ManifestHeaderComponent)), i0.ɵdid(2, 770048, null, 0, i2.ManifestHeaderComponent, [i3.ManifestService, i4.CuscarTypeService, i5.NotificationService], { roadManifest: [0, "roadManifest"] }, { onUpdateRoadManifest: "onUpdateRoadManifest" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "digi-manifest-edi", [], null, [[null, "updateRoadManifest"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateRoadManifest" === en)) {
        var pd_0 = (_co.updateRoadManifest($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_ManifestEdiComponent_0, i18.RenderType_ManifestEdiComponent)), i0.ɵdid(4, 4964352, null, 0, i19.ManifestEdiComponent, [i3.ManifestService, i5.NotificationService, i20.SendEdiToSarsService, i4.CuscarTypeService], { roadManifest: [0, "roadManifest"], view: [1, "view"] }, { updateRoadManifest: "updateRoadManifest" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roadManifest; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.roadManifest; var currVal_2 = "edi"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_ManifestComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 12, "p-tabView", [["orientation", "left"], ["styleClass", "stepMenu"]], null, null, null, i11.View_TabView_0, i11.RenderType_TabView)), i0.ɵdid(3, 1097728, null, 1, i12.TabView, [i0.ElementRef], { orientation: [0, "orientation"], styleClass: [1, "styleClass"] }, null), i0.ɵqud(603979776, 3, { tabPanels: 1 }), (_l()(), i0.ɵeld(5, 16777216, null, 0, 4, "p-tabPanel", [["header", "Generate"]], null, null, null, i11.View_TabPanel_0, i11.RenderType_TabPanel)), i0.ɵdid(6, 1228800, [[3, 4]], 1, i12.TabPanel, [i0.ViewContainerRef], { header: [0, "header"] }, null), i0.ɵqud(603979776, 4, { templates: 1 }), (_l()(), i0.ɵand(0, null, 0, 1, null, View_ManifestComponent_5)), i0.ɵdid(9, 16384, [[4, 4]], 0, i13.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null), (_l()(), i0.ɵeld(10, 16777216, null, 0, 4, "p-tabPanel", [["header", "Confirm Manifest"]], null, null, null, i11.View_TabPanel_0, i11.RenderType_TabPanel)), i0.ɵdid(11, 1228800, [[3, 4]], 1, i12.TabPanel, [i0.ViewContainerRef], { header: [0, "header"] }, null), i0.ɵqud(603979776, 5, { templates: 1 }), (_l()(), i0.ɵand(0, null, 0, 1, null, View_ManifestComponent_6)), i0.ɵdid(14, 16384, [[5, 4]], 0, i13.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "left"; var currVal_1 = "stepMenu"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "Generate"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "content"; _ck(_v, 9, 0, currVal_3); var currVal_4 = "Confirm Manifest"; _ck(_v, 11, 0, currVal_4); var currVal_5 = "content"; _ck(_v, 14, 0, currVal_5); }, null); }
export function View_ManifestComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p-steps", [], null, [[null, "activeIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeIndexChange" === en)) {
        var pd_0 = ((_co.activeIndex = $event) !== false);
        ad = (pd_0 && ad);
    } if (("activeIndexChange" === en)) {
        var pd_1 = (_co.saveRoadManifest() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i21.View_Steps_0, i21.RenderType_Steps)), i0.ɵdid(2, 49152, null, 0, i22.Steps, [], { activeIndex: [0, "activeIndex"], model: [1, "model"], readonly: [2, "readonly"] }, { activeIndexChange: "activeIndexChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ManifestComponent_1)), i0.ɵdid(4, 16384, null, 0, i23.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ManifestComponent_3)), i0.ɵdid(6, 16384, null, 0, i23.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ManifestComponent_4)), i0.ɵdid(8, 16384, null, 0, i23.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; var currVal_1 = _co.items; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.view === "register"); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.view === "waybills"); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.view === "manifest"); _ck(_v, 8, 0, currVal_5); }, null); }
export function View_ManifestComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "digi-manifest", [], null, null, null, View_ManifestComponent_0, RenderType_ManifestComponent)), i0.ɵdid(1, 245760, null, 0, i24.ManifestComponent, [i3.ManifestService, i25.Router, i10.ExportsBillOfEntryService, i5.NotificationService, i16.CompanyService, i26.AgentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManifestComponentNgFactory = i0.ɵccf("digi-manifest", i24.ManifestComponent, View_ManifestComponent_Host_0, {}, {}, []);
export { ManifestComponentNgFactory as ManifestComponentNgFactory };
