import {Uom} from '../../digi-common/models/TariffCode';

export class BusinessEntity {
  id?: string;
  beid?: number;
  name?: string;
  code?: string;
  compRegNo?: string;
  startDate?: string;
  endDate?: string;
  registeredName?: string;
  beComplex?: boolean;
  descriptor?: string;
  beSearchName?: string;
  status?: string;
  defaultChargeMethod?: string;
  deliveryNoteInstruction?: string;
  defaultVatRegNo?: string;
  defaultCustomsCode?: string;
  sendTrackingEmail?: boolean;
  canOverwriteAddress?: boolean;
  archiveFolderId?: number;
  globalId?: string;
  branchCode?: number;
  label?: string;

  [k: string]: any;

  addresses?: Address[];
  roles?: Role[];

}

export class Address {
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  zipCode?: string;
  telephone?: string;
  description?: string;
}

export class Role {
  roleType?: number;
  roleNominations?: RoleNomination[];
  roleAddresses?: RoleAddress[];
  [k: string]: any;
}

export class Importer extends Role {
  defaultCustomsCode?: string;
  registrationType?: CustomsTraderType;
  defaultVatRegNo?: string;
  country?: Country;
  costingRequired?: boolean;
  costingUom?: Uom;
  idNumber?: string;
  passportNumber?: string;
  reportsDefaultEmail?: string;
}

export class Debtor extends Role {
  country?: Country;
  currency?: Currency;
  status?: string;
  creditTerms?: string;
  creditBalance?: string;
  insCreditLimit?: string;
  creditLimit?: string;
  includeFreightInCreditLimit?: string;
  externalDebtorAccountNumber?: string;
}

export class Currency {
  name?: string;
  code?: string;
  label?: string;
  exchangeRate?: number;
}

export class Country {
  code?: string;
  defaultTradeAgreement?: TradeAgreement;
  name?: string;
  label?: string;
  tradeAgreements?: TradeAgreement[];
}

export class TradeAgreement {
  code?: string;
  description?: string;
}

export class RebateUser extends Role {
  customsCode?: string;
}

export class ReceivingBank {
  code?: string;
  name?: string;
  shortName?: string;
}

export class Bank {
  name?: string;
}

export class Exporter extends Role {
  defaultCustomsCode?: string;
  registrationType?: CustomsTraderType;
  defaultVatRegNo?: string;
  country?: Country;
  currency?: Currency;
  rooNumber?: string;
  receivingBank?: ReceivingBank;
  bank?: string;
  hasUnknownShipper?: boolean; //Todo : solve error looking at hasBankingDetails
}

export class Supplier extends Role {
  registrationType?: CustomsTraderType;
  country?: Country;
  currency?: Currency;
}

export class ExportConsignee extends Role {
  defaultCustomsCode?: string;
  country?: Country;
  termsOfPayment?: string;
  hasBankingDetails?: boolean;
  commercialBankName?: string;
  commercialBankCode?: string;
  commercialBankAccNo?: string;
  commercialBankSwiftCode?: string;
}

export class OverseasAgent extends Role {
  defaultCustomsCode?: string;
  country?: Country;
  currency?: Currency;
}

export class CustomsTraderType {
  id?: number;
  description?: string;
}

export class RoleNomination {
  roleType?: number;
  businessEntityName?: string;
  businessEntityCode?: string;
  label?: string;
  defaultPurchaseTerm?: PurchaseTerm;
  [k: string]: any;
}

export class ImporterSupplier extends RoleNomination {
  vdnNumber?: string;
  vdnMarkup?: number;
  defaultPurchaseTerm?: PurchaseTerm;
  expCustomsCode?: string;
  defaultValuationCode?: string;
  defaultValuationMethod?: number;
  vdnWarning?: boolean;
}

export class ValuationMethod {
  code?: number;
  description?: string;
  label?: string;
}

export class ValuationCode {
  code?: string;
  description?: string;
  label?: string;
}

export class Creditor extends Role {
  externalCreditorCode?: string;
  currency?: Currency;
  exchangeRateType?: ExchangeRateType;
  defaultTaxType?: TaxType;
}

export class LocalAgent extends Role {
  depotTerminal?: DepotTerminal;
  warningMessage?: string;
  iataCode?: string;
  customsRegCode?: string;
  localAgentCustomOfficeCreditors?: LocalAgentCustomOfficeCreditor[];
  localAgentCustomOfficeBonds?: LocalAgentCustomOfficeBond[];
}

export class LocalAgentCustomOfficeBond {
  customsOffice?: CustomOffice;
  bondNo?: string;
  expiryDate?: Date;
}

export class CustomsOffice {
  districtOfficeCode?: string;
  districtOfficeName?: string;
}

export class Branch extends Role {

}

export class PurchaseTerm {
  code?: string;
  description?: string;
  label?: string;

}

export class RoleAddress {
  addressType?: AddressType;
  address?: Address;
}

export class AddressType {
  id?: string;
  description?: string;
}

export class ImporterDebtor extends RoleNomination {

}

export class ImporterRebateUser extends RoleNomination {

}

export class ImporterRemover extends RoleNomination {

}

export class ImporterWarehouse extends RoleNomination {

}

export class ImporterGuarantor extends RoleNomination {

}

export class ImporterConsignee extends RoleNomination {

}

export class ExporterConsignee extends RoleNomination {
  defaultPurchaseTerm?: PurchaseTerm;
}

export class ExporterOverseasAgent extends RoleNomination {

}

export class ExporterDebtor extends RoleNomination {

}

export class ExporterWarehouse extends RoleNomination {
  customsWarehouseNumber?: string;
}

export class ExchangeRateType {
  type?: string;
  description?: string;
  effectiveUntil?: string;
  label?: string;
}

export class TaxType {
  code?: string;
  description?: string;
  sarsTaxCode?: string;
  label?: string;
}

export class PaymentType {
  paymentCode?: string;
  paymentDesc?: string;
  uuid?: number;
  label?: string;
}

export class DepotTerminal {
  code?: string;
  name?: string;
  label?: string;

  [k: string]: any;
}

export class CustomOffice {
  districtOfficeCode?: string;
  districtOfficeName?: string;

  [k: string]: any;
}

export class LocalAgentCustomOfficeCreditor {
  customsOffice?: CustomOffice;
  paymentType?: PaymentType;
  creditorNominated?: CreditorNominated;
  customsBoxNo?: string;
  financialAccountNo?: string;
  deferredStartDay?: number;
  deferredEndDay?: number;
  deferredPaymentDay?: number;
  deferredLimit?: number;
  deferredBalance?: number;
  waringLimit?: number;
}

export class CreditorNominated {
  roleType?: number;
  businessEntityCode?: string;
  businessEntityName?: string;
  label?: string;
}

export class Guarantor extends Role {
  guaranteeType?: GuaranteeType;
}

export class GuaranteeType {
  code?: string;
  description?: string;
  label?: string;

  [k: string]: any;
}

export class Remover extends Role {
  customsCode?: string;
  bondValue?: number;

  [k: string]: any;
}

export class Warehouse extends Role {
  customsWarehouseNumber?: string;

  [k: string]: any;
}

export class Haulier extends Role {
  customsCode?: string;
}

export class NominatedBusinessEntity {
  businessEntity?: BusinessEntity;
  roleNomination?: RoleNomination;

}

export class ExporterConsignor extends RoleNomination {

}

export class Consignor extends Role {
  customsCode?: string;
}
