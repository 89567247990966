import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ExportFile } from '../models/exportFile';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ExportFileBehaviourSubject } from '../../../subjects/exportfile-behaviour-subject';
import { NotificationService } from '../services/notification.service';
import { ExportFileService } from '../services/export-file.service';
import {ExportTransportDocument, SupplierInvoice, SupplierInvoiceLine, TransportDocument} from '../models/file';
import {TransportDocumentBehaviourSubject} from '../../../subjects/transport-document-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../subjects/supplierInvoice-behaviour-subject';
import {SupplierInvoiceLineBehaviourSubject} from '../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {ValidationResult, ValidationService} from '../services/validation.service';
import {BillOfEntry, ExportBillOfEntry} from '../models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {ClearingFile} from '../models/clearingFile';

@Component({
  selector: 'digi-exports-transport-steps-navigation',
  templateUrl: './exports-transport-steps-navigation.component.html',
  styles: [`
      .ui-steps-item {
          width: 16.6%
      }
  `],
  encapsulation: ViewEncapsulation.None
})
export class ExportsTransportStepsNavigationComponent implements OnInit, OnDestroy {

  @Input() transportMethod;
  items: MenuItem[];
  exportFile: ExportFile;
  billOfEntry: ExportBillOfEntry;
  activeIndex = 0;
  flag: boolean;
  selectedTransportDocument: ExportTransportDocument;
  selectedSupplierInvoice: SupplierInvoice;
  selectedSupplierInvoiceLine: SupplierInvoiceLine;

  exportFileSubscription: Subscription;
  transSubscription: Subscription;
  supplierSubscription: Subscription;
  supplierLineSubscription: Subscription;
  billOfEntrySubscription: Subscription;

  constructor(
    private router: Router,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private messageService: NotificationService,
    private exportFileService: ExportFileService,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private supplierInvoiceLineBehaviourSubject: SupplierInvoiceLineBehaviourSubject,
    private validationService: ValidationService,
    private billOfEntryBehaviorSubject: BillOfEntryBehaviourSubject,
  ) {
    this.validationService.setComponent(this);
  }

  ngOnInit() {
    this.exportFileSubscription = this.exportFileBehaviourSubject.getExportFile()
      .subscribe(ef => this.exportFile = ef);

    this.transSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: ExportTransportDocument) => (this.selectedTransportDocument = td));

    this.supplierSubscription = this.supplierInvoiceBehaviourSubject.getSupplierInvoice()
      .subscribe((si: SupplierInvoice) => (this.selectedSupplierInvoice = si));

    this.supplierLineSubscription = this.supplierInvoiceLineBehaviourSubject.getSupplierInvoiceLine()
      .subscribe((line: SupplierInvoiceLine) => this.selectedSupplierInvoiceLine = line);

    this.billOfEntrySubscription = this.billOfEntryBehaviorSubject.getBillOfEntry()
      .subscribe(billOfEntry => this.billOfEntry = billOfEntry);

    this.items = [
      {
        label: 'Registration',
        command: (event: any) => {
          this.activeIndex = 0;
          this.router.navigate([`/export/${this.transportMethod}/register`]);
        },
        icon: 'fa fa-user-plus'
      },
      {
        label: 'Transport Document',
        disabled: this.flag,
        command: (event: any) => {
          let isConsolidationValid = true;
          let consolidationFailMessage = '';
          if (this.exportFile.transportDocument && this.exportFile.transportDocument.consolidation) {
            if (!this.exportFile.consolidationTransportDocuments) {
              isConsolidationValid = false;
              consolidationFailMessage = 'No HAWBs captured.';
            } else if (Number(this.exportFile.transportDocument.noOfConsolidatedHawbs) !== this.exportFile.consolidationTransportDocuments.length) {
              isConsolidationValid = false;
              consolidationFailMessage = 'Please ensure that the number of captured HAWBs match the number of HAWBs specified in MAWB.';
            }
          }
          const validationResult = this.validationService.validateRegistration(this.exportFile, 'exports');
          if (validationResult.isValid && isConsolidationValid) {
            this.flag = true;
            this.activeIndex = 1;
            if (!this.isSaveDisabled()) {
              this.exportFileService.saveExportsFile(this.exportFile,
                this.selectedTransportDocument,
                this.selectedSupplierInvoice,
                this.selectedSupplierInvoiceLine).subscribe(
                _ => {
                  this.router.navigate([`/export/${this.transportMethod}/transport`]);
                  this.saveConfirmation();
                }
              );
            } else {
              this.router.navigate([`/export/${this.transportMethod}/transport`]);
            }
          } else if (!validationResult.isValid) {
            this.activeIndex = 0;
            this.showMessages(validationResult.messages);
          } else {
            this.activeIndex = 0;
            this.showMessages([consolidationFailMessage]);
          }
        },
        icon: 'fa fa-fw fa-calendar'
      },
      {
        label: 'Supplier Invoices',
        disabled: this.flag,
        command: (event: any) => {
          this.flag = true;
          const validationResult = this.validationService.validateTransportDocuments(this.exportFile, 'exports');
          if (validationResult.isValid) {
            this.activeIndex = 2;
            if (!this.isSaveDisabled()) {
              this.exportFileService.saveExportsFile(this.exportFile,
                this.selectedTransportDocument,
                this.selectedSupplierInvoice,
                this.selectedSupplierInvoiceLine).subscribe(
                value => {
                  this.router.navigate([`/export/${this.transportMethod}/supplier-invoice`]);
                  this.saveConfirmation();
                }
              );
            } else {
              this.router.navigate([`/export/${this.transportMethod}/supplier-invoice`]);
            }
          } else {
            this.activeIndex = 1;
            this.showMessages(validationResult.messages);
          }
        },
        icon: 'fa fa-file'
      },
      {
        label: 'Bills of Entry',
        command: (event: any) => {
          let validationResult = this.validationService.validateSupplierInvoices(this.exportFile, 'exports');
          if (validationResult.isValid) {
            validationResult = this.validationService.validateSupplierInvoiceLines(this.exportFile, 'exports');
          }
          if (validationResult.isValid) {
            this.activeIndex = 3;
            if (!this.isSaveDisabled()) {
              this.exportFileService.saveExportsFile(this.exportFile,
                this.selectedTransportDocument,
                this.selectedSupplierInvoice,
                this.selectedSupplierInvoiceLine).subscribe(
                value => {
                  this.router.navigate([`/export/${this.transportMethod}/generate`]);
                  this.saveConfirmation();
                }
              );
            } else {
              this.router.navigate([`/export/${this.transportMethod}/generate`]);
            }
          } else {
            this.activeIndex = 1;
            this.showMessages(validationResult.messages);
          }
        },
        icon: 'fa fa-fw fa-support'
      },
      {
        label: 'Financials',
        command: (event: any) => {
          if (this.exportFile.clearingFileNumber) {
            this.activeIndex = 4;
            this.router.navigate([`/export/${this.transportMethod}/financials`]);
          } else {
            this.activeIndex = 0;
            this.showMessages(['Load Export File first.']);
          }
        },
        icon: 'fa fa-file'
      }
    ];
  }

  ngOnDestroy(): void {
    this.exportFileSubscription.unsubscribe();
  }

  saveConfirmation() {
    this.messageService.successNotify('File Saved Successfully');
  }

  showMessages(messages: string[]) {
    this.messageService.errorNotify('Validation Failed', messages);
    this.flag = true;
  }

  isSaveDisabled() {
    return !!(this.billOfEntry && this.billOfEntry.dateEdiSubmitted);
  }
}
