import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var ValidationService = /** @class */ (function () {
    function ValidationService(http, config) {
        this.http = http;
        this.config = config;
    }
    ValidationService.prototype.setComponent = function (component) {
        this.component = component;
    };
    ValidationService.prototype.validateFileBasedOnStep = function (step, file, fileType, errorsCallBack) {
        var failed = false;
        switch (step) {
            case 'registration': {
                var result = this.validateRegistration(file, fileType);
                if (!result.isValid) {
                    failed = errorsCallBack('Validation For Registration Failed', result.messages);
                }
                break;
            }
            case 'transportDocument': {
                if (file.transportDocument) {
                    var result = this.validateTransportDocuments(file, fileType);
                    if (!result.isValid) {
                        failed = errorsCallBack('Validation For Transport Document Failed', result.messages);
                    }
                }
                break;
            }
            case 'supplierInvoice': {
                var result = this.validateSupplierInvoicesIndependently(file, fileType); //Todo
                if (!result.isValid) {
                    failed = errorsCallBack('Validation For Supplier Invoices Failed', result.messages);
                }
                if (!failed) {
                    result = this.validateSupplierInvoiceLinesIndependently(file, fileType); //Todo
                    if (!result.isValid) {
                        failed = errorsCallBack('Validation For Supplier Invoice Lines Failed', result.messages);
                    }
                }
                break;
            }
        }
        return failed;
    };
    ValidationService.prototype.validateRegistration = function (file, type) {
        var _this = this;
        var result = { isValid: true, messages: [] };
        var isQuotation = file.quotation;
        if ((type === 'imports' || type === 'exbond') && !file.importer.code && !isQuotation) {
            this.fail(result, 'Please select Importer.');
        }
        if ((type === 'imports' || type === 'exports' || type === 'exbond')) {
            if (!file.reasons) {
                if (file.sequence !== 0 && file.clearingFileNumber) {
                    this.fail(result, 'Please enter reason for correction.');
                }
            }
        }
        if (type === 'exbond' && !file.warehouse.code) {
            this.fail(result, 'Please select Warehouse.');
        }
        if (type === 'exports' && !file.exporter.code && !isQuotation) {
            this.fail(result, 'Please select Exporter.');
        }
        if (type === 'exports' && !file.exportConsignee.code && !isQuotation) {
            this.fail(result, 'Please select Export Consignee.');
        }
        if (!file.localAgent.code) {
            if ((type === 'imports' || type === 'exbond') && !isQuotation) {
                this.fail(result, 'Please select Clearing Agent.');
            }
            else if (!isQuotation) {
                this.fail(result, 'Please select Local Agent.');
            }
        }
        else {
            if ((!file.localAgent.customsRegCode || file.localAgent.customsRegCode === '') && !isQuotation) {
                if (type === 'imports') {
                    this.fail(result, 'Clearing Agent is missing Customs Code. Please Reload Clearing Agent');
                }
                else {
                    this.fail(result, 'Local Agent is missing Customs Code. Please Reload Local Agent');
                }
            }
        }
        if (!file.customOffice.districtOfficeCode && !isQuotation) {
            this.fail(result, 'Please select Custom Office.');
        }
        if (!file.clearingInstructions[0].customsPurpose.customsPurposeCode) {
            this.fail(result, 'Please select At least One Clearing Instruction.');
        }
        if (type !== 'exbond') {
            file.clearingInstructions.forEach(function (ci) {
                if (!ci.transportMethod || (ci.transportMethod && !ci.transportMethod.method)) {
                    _this.fail(result, "Please Select A Transport Mode for " + ci.customsPurpose.customsPurposeCode);
                }
                if (!ci.customsProcedureCode) {
                    _this.fail(result, "Please Select A Procedure Code for " + ci.customsPurpose.customsPurposeCode);
                }
            });
        }
        if (type === 'exports' && file.clearingInstructions[0].transportMethod.method === 'AIR' && file.transportDocument && !isQuotation) {
            if (file.transportDocument.shipmentType === 'BACK_TO_BACK') {
                if (!file.transportDocument.overseasAgent || (file.transportDocument.overseasAgent && !file.transportDocument.overseasAgent.code)) {
                    this.fail(result, 'Please select Overseas Agent.');
                }
            }
        }
        return result;
    };
    ValidationService.prototype.validateDateIsBefore = function (testDate) {
        var date = moment(testDate, 'DD/MM/YYYY');
        var today = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY');
        return date.isBefore(today);
    };
    ValidationService.prototype.validateCosting = function (allocatedLines) {
        var result = { isValid: true, messages: [] };
        this.validateCostingLinesUnits(allocatedLines, result);
        return result;
    };
    ValidationService.prototype.validateCostingLinesUnits = function (allocatedLines, result) {
        var invalidLines = allocatedLines.filter(function (line) { return !line.costingValue; });
        if (invalidLines.length > 0) {
            this.fail(result, 'The following costing line number(s) have an invalid unit cost: ['.concat(invalidLines.map(function (line) { return line.invoiceNumber.concat(' Line ').concat(line.lineNo); }).join(', ').concat(']')));
        }
    };
    ValidationService.prototype.validateTransportDocumentOnly = function (transportDocument, transportMethod, type) {
        var result = { isValid: true, messages: [] };
        this.validateTransportDocumentIndependently(transportDocument, transportMethod, result, type);
        return result;
    };
    ValidationService.prototype.validateTransportDocumentIndependently = function (transportDocument, transportMethod, result, type) {
        if (!transportDocument) {
            this.fail(result, 'Please capture Transport Document');
        }
        if (type === 'imports' && !transportDocument.localForwarder.name && !transportDocument.straight) {
            this.fail(result, 'Please select Local Forwarder.');
        }
        if (!transportDocument.freight.currency.code) {
            this.fail(result, 'Please select currency on Freight.');
        }
        if (!transportDocument.freight.collectPrepaid.code) {
            this.fail(result, 'Please select Collect/Prepaid on Freight.');
        }
        if (!transportDocument.measures.numberOfPackages) {
            this.fail(result, 'Please enter Number Of Packages.');
        }
        if (!transportDocument.measures.grossWeight) {
            this.fail(result, 'Please enter Gross Weight.');
        }
        switch (transportMethod) {
            case 'SEA':
                if (!transportDocument.oceanLineDetails.oceanLine.code) {
                    this.fail(result, 'No Ocean lines found.');
                }
                if (!transportDocument.oceanLineDetails.carrierCode.code) {
                    this.fail(result, 'No Carrier Code found.');
                }
                if (!transportDocument.mawbNo) {
                    this.fail(result, 'Please enter MOBL No.');
                }
                if (!transportDocument.hawbNo) {
                    this.fail(result, 'Please enter HBOL No.');
                }
                if (!transportDocument.mawbDate) {
                    this.fail(result, 'Please select SOB Date.');
                }
                if (!transportDocument.hawbDate) {
                    this.fail(result, 'Please select HBOL Date.');
                }
                if (!transportDocument.voyageDetails.voyageNo) {
                    this.fail(result, 'Please enter Voyage Number.');
                }
                if (!transportDocument.voyageDetails.customsRadioCallSign.radioCallSign) {
                    this.fail(result, 'Please select Radio Call Sign.');
                }
                if (!transportDocument.voyageDetails.customsRadioCallSign.vesselName) {
                    this.fail(result, 'Please enter Vessel Name.');
                }
                if (!transportDocument.departurePort.code) {
                    this.fail(result, 'Please select Departure Port.');
                }
                if (!transportDocument.docsIssuedAtPort.code) {
                    this.fail(result, 'Please select DocsIssued Port.');
                }
                if (!transportDocument.dischargePort.code) {
                    this.fail(result, 'Please select Discharge Port.');
                }
                if (!transportDocument.destinationPort.code) {
                    this.fail(result, 'Please select Destination Port.');
                }
                if (type === 'exports' && !transportDocument.exitPort.code) {
                    this.fail(result, 'Please select Exit Port.');
                }
                break;
            case 'AIR':
                if (!transportDocument.airline.code) {
                    this.fail(result, 'No Airlines found.');
                }
                if (!transportDocument.mawbNo) {
                    this.fail(result, 'Please enter MAWB No.');
                }
                else if (transportDocument.validateMawbNo) {
                    var checkDigit = this.calculateCheckDigit(transportDocument.mawbNo);
                    var lastNumber = transportDocument.mawbNo.toString().substring(7, 8);
                    var convertToNumber = +lastNumber;
                    var validMawbNo = transportDocument.mawbNo.toString();
                    if (validMawbNo.length !== 8) {
                        this.fail(result, 'MAWB No should be at least 8 digits long');
                    }
                    else {
                        if (checkDigit !== convertToNumber) {
                            this.fail(result, 'Invalid Airline Check Digit.');
                        }
                    }
                }
                if (type === 'imports' && transportDocument.validateHawbNo && transportDocument.duplicateHawbNo) {
                    this.fail(result, 'Duplicate HAWB Number found.');
                }
                if (!transportDocument.mawbDate) {
                    this.fail(result, 'Please select MAWB Date.');
                }
                if (!transportDocument.hawbDate) {
                    this.fail(result, 'Please select HAWB Date.');
                }
                if (!transportDocument.departureDate) {
                    this.fail(result, 'Please select Departure Date.');
                }
                if (!transportDocument.arrivalDate) {
                    this.fail(result, 'Please select Arrival Date.');
                }
                if (!transportDocument.flight) {
                    this.fail(result, 'Please Enter Flight.');
                }
                if (!transportDocument.depotTerminal.code) {
                    this.fail(result, 'Please select Degroup/Transit Depot.');
                }
                break;
            case 'ROA':
                if (!transportDocument.transporter || !transportDocument.transporter.transporterCode) {
                    this.fail(result, 'No Transporter found.');
                }
                if (!transportDocument.mawbNo && type === 'imports') {
                    this.fail(result, 'Please enter Manifest No.');
                }
                if (!transportDocument.hawbNo && type === 'imports') {
                    this.fail(result, 'Please enter Waybill No.');
                }
                if (!transportDocument.mawbDate) {
                    this.fail(result, 'Please select SOB Date.');
                }
                if (!transportDocument.hawbDate) {
                    this.fail(result, 'Please select HBOL Date.');
                }
                if (!transportDocument.departurePort.code) {
                    this.fail(result, 'Please select Departure Port.');
                }
                if (!transportDocument.docsIssuedAtPort.code) {
                    this.fail(result, 'Please select DocsIssued Port.');
                }
                if (!transportDocument.dischargePort.code) {
                    this.fail(result, 'Please select Discharge Port.');
                }
                if (!transportDocument.destinationPort.code) {
                    this.fail(result, 'Please select Destination Port.');
                }
                break;
        }
        if (!this.isAllSundryValid(transportDocument.valueBasedCharges, transportDocument.rateBasedCharges)) {
            this.fail(result, 'Please ensure that all charges have currency and charge description.');
        }
    };
    ValidationService.prototype.validateTransportDocuments = function (file, type) {
        var _this = this;
        var result = { isValid: true, messages: [] };
        if (file.quotation) {
            return result;
        }
        if (type === 'imports') {
            if (!file.transportDocuments || !file.transportDocuments.length) {
                this.fail(result, 'Please capture Transport Document');
                return result;
            }
        }
        else {
            if (!file.transportDocument) {
                this.fail(result, 'Please capture Transport Document');
                return result;
            }
        }
        if (type === 'exports' && file.transportDocument && file.transportDocument.consolidation) {
            if (!file.consolidationTransportDocuments) {
                this.fail(result, 'No HAWBs captured.');
            }
            else if (Number(file.transportDocument.noOfConsolidatedHawbs) !== file.consolidationTransportDocuments.length) {
                this.fail(result, 'Please ensure that the number of captured HAWBs match the number of HAWBs specified in MAWB.');
            }
        }
        if ((type === 'imports' || type === 'exports' || type === 'exbond')) {
            if (!file.reasons) {
                if (file.sequence !== 0 && file.clearingFileNumber) {
                    this.fail(result, 'Please enter reason for correction.');
                }
            }
        }
        var transportMethod = file.clearingInstructions[0].transportMethod.method;
        var transportDocuments = type === 'imports' ? file.transportDocuments : [file.transportDocument];
        transportDocuments.forEach(function (transportDocument) {
            _this.validateTransportDocumentIndependently(transportDocument, transportMethod, result, type);
            switch (transportMethod) {
                case 'SEA':
                    if ((transportDocument.containers.length > 0 && type === 'imports')) {
                        transportDocument.containers.forEach(function (c) {
                            if (!c.containerType.code) {
                                _this.fail(result, 'No Container Type found.');
                            }
                            if ((c.containerType.code
                                && c.containerType.code.trim() !== 'BBK'
                                && c.containerType.code.trim() !== 'BLK') && !c.containerNo) {
                                _this.fail(result, 'Please enter Container Number.');
                            }
                            if ((c.containerType.code
                                && c.containerType.code.trim() !== 'BBK'
                                && c.containerType.code.trim() !== 'BLK')
                                && !c.containerSpecification.containerSize.code) {
                                _this.fail(result, 'Please select Container Size.');
                            }
                            if ((c.containerType.code
                                && c.containerType.code.trim() !== 'BBK'
                                && c.containerType.code.trim() !== 'BLK')
                                && !transportDocument.measures.noOfContainers) {
                                _this.fail(result, 'Please enter No Of Containers.');
                            }
                            _this.isTerminalDepotValid(result, c.terminal, c.depot);
                        });
                    }
                    break;
                case 'ROA':
                    if (transportDocument.containers.length > 0) {
                        transportDocument.containers.forEach(function (c) {
                            if (c.containerType.code && (c.containerType.code.trim() === 'LCL' || c.containerType.code.trim() === 'FCLG')
                                && !c.containerSpecification.containerSize.code) {
                                _this.fail(result, 'Please select Container Size.');
                            }
                        });
                    }
                    break;
                case 'AIR':
                    // Do nothing already validated in above call.
                    break;
            }
        });
        return result;
    };
    ValidationService.prototype.isTerminalDepotValid = function (result, terminal, depot) {
        var hasTerminal = false;
        var hasDepot = false;
        if (terminal && terminal.code) {
            hasTerminal = true;
        }
        if (depot && depot.code) {
            hasDepot = true;
        }
        if (hasTerminal && hasDepot) {
            this.fail(result, 'Please select only one of Terminal Or Depot.');
        }
        if (!hasTerminal && !hasDepot) {
            this.fail(result, 'Please select either a Terminal Or Depot.');
        }
    };
    ValidationService.prototype.validateSupplierInvoices = function (file, type) {
        var _this = this;
        var result = { isValid: true, messages: [] };
        if (type === 'imports') {
            if (!file.transportDocuments || !file.transportDocuments.length) {
                this.fail(result, 'Please capture Transport Document');
                return result;
            }
            file.transportDocuments.forEach(function (t) {
                if (!t.supplierInvoices || !t.supplierInvoices.length) {
                    _this.fail(result, 'Please capture Supplier Invoice');
                    return result;
                }
            });
        }
        else {
            if (!file.transportDocument) {
                this.fail(result, 'Please capture Transport Document');
                return result;
            }
            if (!file.transportDocument.supplierInvoices || !file.transportDocument.supplierInvoices.length) {
                this.fail(result, 'Please capture Supplier Invoice');
                return result;
            }
        }
        return this.validateSupplierInvoicesIndependently(file, type);
    };
    ValidationService.prototype.validateSupplierInvoicesIndependently = function (file, type) {
        var _this = this;
        var result = { isValid: true, messages: [] };
        if (file.quotation) {
            return result;
        }
        if (type === 'imports') {
            file.transportDocuments.forEach(function (t) {
                _this.validateSupplierInvoicesOnTransportDocument(t.supplierInvoices, type, result, file.clearingInstructions[0].transportMethod.method, file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim());
            });
        }
        else {
            this.validateSupplierInvoicesOnTransportDocument(file.transportDocument.supplierInvoices, type, result, file.clearingInstructions[0].transportMethod.method, file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim());
        }
        return result;
    };
    ValidationService.prototype.validateSupplierInvoicesOnTransportDocument = function (supplierInvoices, type, result, transportMethod, customsPurposeCode) {
        var _this = this;
        supplierInvoices.forEach(function (s) {
            if (!s.invoiceNumber) {
                _this.fail(result, 'Please Enter Invoice Number');
            }
            if (!s.invoiceDate) {
                _this.fail(result, 'Please Enter Invoice Date');
            }
            if (!s.defaultValues.country || !s.defaultValues.country.code) {
                _this.fail(result, 'Please select Country.');
            }
            if (!s.defaultValues.purchaseTerm || !s.defaultValues.purchaseTerm.code) {
                _this.fail(result, 'Please select Purchase Term.');
            }
            if (!s.currency || (s.currency && !s.currency.code)) {
                _this.fail(result, 'Please select Currency.');
            }
            if (!s.totalGrossIncomeValue && ((customsPurposeCode !== 'WH' && customsPurposeCode !== 'WE') || type !== 'imports')) {
                _this.fail(result, 'Please Enter Total Gross Value.');
            }
            if (!s.quantity && ((customsPurposeCode !== 'WH' && customsPurposeCode !== 'WE') || type !== 'imports')) {
                _this.fail(result, 'Please Enter Quantity.');
            }
            if (type !== 'imports' && transportMethod === 'SEA') {
                if (!s.ucrNumber) {
                    _this.fail(result, 'Please Add UCR.');
                }
                if (s.supplier && !s.supplier.code) {
                    _this.fail(result, 'Please select Export Consignee.');
                }
            }
            var invoiceDate = moment(s.invoiceDate, 'DD/MM/YYYY');
            var today = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY');
            if (invoiceDate.isAfter(today)) {
                _this.fail(result, 'Invoice date cannot be a future date');
            }
            var isSaveDisabled = _this.component.isSaveDisabled();
            if (!isSaveDisabled) {
                // tslint:disable-next-line:max-line-length
                if (!_this.isAllSundryValid(s.valueBasedCharges, s.rateBasedCharges) || !_this.isValueBasedChargeOptionSelected(s.valueBasedCharges)) {
                    _this.fail(result, 'Please ensure that all charges have currency, charge description, and required options are selected.');
                }
            }
        });
    };
    ValidationService.prototype.validateSupplierInvoiceLines = function (file, type) {
        var _this = this;
        var result = { isValid: true, messages: [] };
        if (file.quotation) {
            return result;
        }
        if (type === 'imports') {
            if (!file.transportDocuments || !file.transportDocuments.length) {
                this.fail(result, 'Please capture Transport Document');
                return result;
            }
            if ((type === 'imports' || type === 'exports' || type === 'exbond')) {
                if (!file.reasons) {
                    if (file.sequence !== 0 && file.clearingFileNumber) {
                        this.fail(result, 'Please enter reason for correction.');
                    }
                }
            }
            file.transportDocuments.forEach(function (t) {
                if (!t.supplierInvoices || !t.supplierInvoices.length) {
                    _this.fail(result, 'Please capture Supplier Invoice');
                    return result;
                }
                t.supplierInvoices.forEach(function (s) {
                    if (!s.lines || !s.lines.length) {
                        _this.fail(result, 'Please capture Supplier Invoice Lines for invoice: ' + s.invoiceNumber);
                        return result;
                    }
                    // if (s.lines.length) {
                    //   if (s.lines.length && s.runningTotals && s.runningTotals.capturedValue !== +s.totalGrossIncomeValue) {
                    //     this.fail(result, 'The Captured invoice value is not equal to the Total Invoice Value for invoice: ' + s.invoiceNumber);
                    //   }
                    // }
                });
            });
        }
        else {
            if (!file.transportDocument) {
                this.fail(result, 'Please capture Transport Document');
                return result;
            }
            if (!file.transportDocument.supplierInvoices || !file.transportDocument.supplierInvoices.length) {
                this.fail(result, 'Please capture Supplier Invoice');
                return result;
            }
            file.transportDocument.supplierInvoices.forEach(function (s) {
                if (!s.lines || !s.lines.length) {
                    _this.fail(result, 'Please capture Supplier Invoice Lines');
                    return result;
                }
            });
        }
        if (!result.isValid) {
            return result;
        }
        return this.validateSupplierInvoiceLinesIndependently(file, type);
    };
    ValidationService.prototype.validateSupplierInvoiceLinesIndependently = function (file, type) {
        var _this = this;
        var result = { isValid: true, messages: [] };
        if (type === 'imports') {
            file.transportDocuments.forEach(function (t) {
                _this.validateSupplierInvoiceLinesOnTransportDocument(file, t.supplierInvoices, type, result);
            });
        }
        else {
            this.validateSupplierInvoiceLinesOnTransportDocument(file, file.transportDocument.supplierInvoices, type, result);
        }
        if (file.clearingInstructions.length > 1) {
            file.clearingInstructions.forEach(function (ci) {
                var matched = false;
                if (type === 'imports') {
                    file.transportDocuments.forEach(function (t) {
                        t.supplierInvoices.forEach(function (si) {
                            si.lines.forEach(function (l) {
                                if (!matched) {
                                    matched = l.instructionUUID === ci.uuid;
                                }
                            });
                        });
                    });
                }
                else {
                    file.transportDocument.supplierInvoices.forEach(function (si) {
                        si.lines.forEach(function (l) {
                            if (!matched) {
                                matched = l.instructionUUID === ci.uuid;
                            }
                        });
                    });
                }
                if (!matched) {
                    _this.fail(result, "Clearing Instruction [" + ci.customsPurpose.customsPurposeCode + ", " + ci.customsProcedureCode + "] is not associated to any line.");
                }
            });
        }
        return result;
    };
    ValidationService.prototype.validateSupplierInvoiceLinesOnTransportDocument = function (file, supplierInvoices, type, result) {
        var _this = this;
        if (file.quotation) {
            return result;
        }
        supplierInvoices.forEach(function (s) {
            if (s.lines.length > 0) {
                s.lines.forEach(function (l) {
                    if (!l.tariffCode.code) {
                        _this.fail(result, "Please select Tariff on line #" + l.lineNo + " of invoice " + s.invoiceNumber + ".");
                    }
                    if (!l.invoiceGrossValue) {
                        _this.fail(result, "Please Enter Invoice Gross Value on line #" + l.lineNo + " of invoice " + s.invoiceNumber + ".");
                    }
                    if (!l.lineQuantity) {
                        _this.fail(result, "Please Enter Line Quantity on line #" + l.lineNo + " of invoice " + s.invoiceNumber);
                    }
                    if (!l.lineWeight) {
                        _this.fail(result, "Please Enter Line Weight on line #" + l.lineNo + " of invoice " + s.invoiceNumber);
                    }
                    if (file.clearingInstructions.length > 1 &&
                        ((l.instructionIndex === null || l.instructionIndex === undefined) && !l.instructionUUID)) {
                        _this.fail(result, "Please select Clearing Instruction To link on line #" + l.lineNo + " of invoice " + s.invoiceNumber);
                    }
                    if (type !== 'imports' && file.clearingInstructions[0].transportMethod.method === 'SEA') {
                        if (!l.ucrNumber) {
                            _this.fail(result, "Please Add UCR on line #" + l.lineNo + " of invoice " + s.invoiceNumber + ".");
                        }
                    }
                    if (l.additionalUoms && l.additionalUoms.length > 0) {
                        var invalidEntry = l.additionalUoms.find(function (el) { return !el.value; });
                        if (invalidEntry) {
                            _this.fail(result, "Please add value for Additional UOM in line " + l.lineNo + " of " + s.invoiceNumber);
                        }
                    }
                    if (file.clearingInstructions[l.instructionIndex].customsPurpose.customsPurposeCode.trim() === 'WH' ||
                        file.clearingInstructions[l.instructionIndex].customsPurpose.customsPurposeCode.trim() === 'WE') {
                        if (!l.noOfPacks) {
                            _this.fail(result, "Please Add Number of Packs on line #" + l.lineNo + " of invoice " + s.invoiceNumber + ".");
                        }
                        if (!l.packType) {
                            _this.fail(result, "Please Add Pack Type on line #" + l.lineNo + " of invoice " + s.invoiceNumber + ".");
                        }
                    }
                });
            }
        });
    };
    ValidationService.prototype.fail = function (result, message) {
        result.isValid = false;
        result.messages.push(message);
    };
    ValidationService.prototype.calculateCheckDigit = function (mawbNo) {
        var firstSeven = mawbNo.toString().substring(0, 7);
        var convertToNumber = +firstSeven;
        var dividedNumber = convertToNumber / 7;
        var roundedValue = Math.floor(dividedNumber);
        var multipliedNumber = roundedValue * 7;
        return convertToNumber - multipliedNumber;
    };
    ValidationService.prototype.isAllSundryValid = function (valueBasedCharges, rateBasedCharges) {
        var _this = this;
        var valueBasedChargesMissingCurrency = valueBasedCharges
            .find(function (valueBasedCharge) { return valueBasedCharge.currency && !valueBasedCharge.currency.code; });
        var rateBasedChargesMissingCurrency = rateBasedCharges
            .find(function (rateBasedCharge) { return rateBasedCharge.currency && !rateBasedCharge.currency.code; });
        var isValueBasedChargesMissingChargeDescription = valueBasedCharges.find(function (v) { return !_this.isValueBasedChargeDescriptionSelected(v); });
        var isRateBasedChargesMissingChargeDescription = rateBasedCharges.find(function (r) { return !_this.isRateBasedChargeDescriptionSelected(r); });
        var isValueBasedChargesMissingAmount = valueBasedCharges.find(function (v) { return !v.amount; });
        var isValueBasedChargesSundryChargeType = valueBasedCharges.find(function (v) { return !v.sundryCharge.code; });
        return !(valueBasedChargesMissingCurrency || rateBasedChargesMissingCurrency
            || isValueBasedChargesMissingChargeDescription
            || isRateBasedChargesMissingChargeDescription
            || isValueBasedChargesMissingAmount
            || isValueBasedChargesSundryChargeType);
    };
    ValidationService.prototype.isValueBasedChargeDescriptionSelected = function (v) {
        return v.nonDutiableValue || v.collect || v.dutiable || v.nonDutiable || v.inlandDutiable || v.inlandNonDutiable || v.inlandDestinationCountry || v.insurance || v.discount || v.freight;
    };
    ValidationService.prototype.isRateBasedChargeDescriptionSelected = function (r) {
        return r.dutiable || r.nonDutiable;
    };
    ValidationService.prototype.isValueBasedChargeOptionSelected = function (valueBasedCharges) {
        return valueBasedCharges.every(function (v) {
            var isOptionSelected = v.collect || v.dutiable || v.inlandDutiable || v.inlandNonDutiable || v.freight || v.discount || v.insurance || v.nonDutiable || v.inlandDestinationCountry;
            var isCollectAndOtherOptionSelected = v.collect && (v.dutiable || v.inlandDutiable || v.inlandNonDutiable || v.freight || v.discount || v.insurance || v.nonDutiable || v.inlandDestinationCountry);
            return isOptionSelected && (!v.collect || isCollectAndOtherOptionSelected);
        });
    };
    ValidationService.ngInjectableDef = i0.defineInjectable({ factory: function ValidationService_Factory() { return new ValidationService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: ValidationService, providedIn: "root" });
    return ValidationService;
}());
export { ValidationService };
