import {Component, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {ExportFile} from '../../models/exportFile';
import {ExportFileService} from '../../services/export-file.service';
import {ExportConsignee} from '../../../digi-business-entity/models/business-entity';
import {CollectPrepaidService} from '../../services/collect-prepaid.service';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExportTransportDocument, Measures} from '../../models/file';
import {AirWaybill, WaybillNumber} from '../../models/AirWaybill';
import {AirWaybillService} from '../../services/air-waybill.service';
import {NotificationService} from '../../services/notification.service';

class SundryCharge {
  code: string;
  amount: number;
}

@Component({
  selector: 'digi-air-waybill',
  templateUrl: './air-waybill.component.html',
  styleUrls: ['./air-waybill.component.scss']
})
export class AirWaybillComponent implements OnInit, OnChanges {
  @Input() exportFile: ExportFile;
  @Input() isHouseBill: boolean;
  @Input() isConsolidation: boolean;
  @Output() onSelect = new EventEmitter;
  @Output() onNewHouseBillAdded = new EventEmitter;
  disableButton: boolean;
  showFilesDialog: boolean;
  displaySundryModal: boolean;
  generatedFiles: any = [];
  filteredCollectPrepaids: any[];
  filteredExportConsignees: ExportConsignee[];
  sundryCharges: SundryCharge[];
  filteredWaybillNumbers: string[];
  selectedConsolidationHouseBill: ExportTransportDocument;
  cols: any[];

  constructor(
    private exportFileService: ExportFileService,
    private collectPrepaidService: CollectPrepaidService,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private airWaybillService: AirWaybillService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.exportFileBehaviourSubject.getExportConsignees().subscribe(
      (exportConsignees: ExportConsignee[]) => this.filteredExportConsignees = exportConsignees
    );
    this.cols = [
      {field: 'hawbNo', header: 'HAWB No', width: '11%'},
      {field: 'consignee', header: 'Consignee'},
      {field: 'destination', header: 'Destination'}
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.exportFile && changes.exportFile.currentValue) {
      this.exportFile = changes.exportFile.currentValue;
      if (this.exportFile.transportDocument) {
        this.createSundryCharges(this.exportFile.transportDocument);
      }

      if (this.exportFile.transportDocument.shipmentType === 'BACK_TO_BACK' || this.isConsolidation && this.exportFile.transportDocument.mawbNo) {
        this.airWaybillService.findByAirlineCode('ZZ').subscribe(
          (airWaybills: AirWaybill[]) => {
            this.filteredWaybillNumbers = this.airWaybillService.filterWaybillNumbers(airWaybills[0], this.exportFile.exporter.code, this.exportFile.clearingFileNumber);
            if (this.filteredWaybillNumbers.length > 0 && !this.exportFile.transportDocument.hawbNo && !this.isConsolidation) {
              this.exportFile.transportDocument.hawbNo = this.filteredWaybillNumbers[0];
              this.updateWaybillPool(this.exportFile.transportDocument.hawbNo);
            }
            if (this.isConsolidation) {
              if(this.exportFile.consolidationTransportDocuments) {
                this.selectedConsolidationHouseBill = this.selectedConsolidationHouseBill ? this.exportFile.consolidationTransportDocuments.find(td => td.hawbNo === this.selectedConsolidationHouseBill.hawbNo) : this.exportFile.consolidationTransportDocuments[0];
                this.onSelect.emit(this.selectedConsolidationHouseBill);
              } else if (this.exportFile.transportDocument.destinationPort.name) {
                this.addNewConsolidationHawb(false);
              }
            }
          }
        );
      }
    }
  }

  loadGeneratedFiles() {
    this.disableButton = true;

    if (this.isHouseBill && this.isConsolidation) {
      this.exportFileService.loadConsolidationFiles(this.exportFile.id, this.selectedConsolidationHouseBill.hawbNo).subscribe(data => {
        this.generatedFiles = data._embedded.supportingDocuments;
        this.showFilesDialog = true;
        this.disableButton = false;
      }, _ => this.disableButton = false);
    } else {
      this.exportFileService.loadFiles(this.exportFile.id).subscribe(data => {
        this.generatedFiles = data._embedded.supportingDocuments;
        this.showFilesDialog = true;
        this.disableButton = false;
      }, _ => this.disableButton = false);
    }
  }

  searchCollectOrPrepaid(event) {
    this.collectPrepaidService.findCollectPrepaidStartWith(event.query).subscribe(data => {
      this.filteredCollectPrepaids = data;
    });
  }

  selectHouseBill(value) {
    this.selectedConsolidationHouseBill = value;
    this.onSelect.emit(value);
  }

  selectCollectOrPrepaid(value) {
    if (this.isConsolidation) {
      this.selectedConsolidationHouseBill.freight.collectPrepaid.code = value.code;
      this.selectedConsolidationHouseBill.freight.collectPrepaid.description = value.description;
    } else {
      this.exportFile.transportDocument.freight.collectPrepaid.code = value.code;
      this.exportFile.transportDocument.freight.collectPrepaid.description = value.description;
    }
  }

  selectConsignee(value) { //Todo : clean and confirm as currently exportConsignee is on file object not TD
    if (this.isConsolidation) {
      this.selectedConsolidationHouseBill.exportConsignee.code = value.code;
      this.selectedConsolidationHouseBill.exportConsignee.name = value.name;
      this.selectedConsolidationHouseBill.exportConsignee.label = value.label;
      this.selectedConsolidationHouseBill.exportConsignee.address = value.address;
      this.selectedConsolidationHouseBill.exportConsignee.country = value.country;
      this.selectedConsolidationHouseBill.exportConsignee.termsOfPayment = value.termsOfPayment;
      this.selectedConsolidationHouseBill.exportConsignee.hasBankingDetails = value.hasBankingDetails;
      this.selectedConsolidationHouseBill.exportConsignee.commercialBankName = value.commercialBankName;
      this.selectedConsolidationHouseBill.exportConsignee.commercialBankCode = value.commercialBankCode;
      this.selectedConsolidationHouseBill.exportConsignee.commercialBankAccNo = value.commercialBankAccNo;
      this.selectedConsolidationHouseBill.exportConsignee.commercialBankSwiftCode = value.commercialBankSwiftCode;
      this.selectedConsolidationHouseBill.exportConsignee.defaultPurchaseTerm = value.purchaseTerm;
    } else {
      this.exportFile.exportConsignee.code = value.code;
      this.exportFile.exportConsignee.name = value.name;
      this.exportFile.exportConsignee.label = value.label;
      this.exportFile.exportConsignee.address = value.address;
      this.exportFile.exportConsignee.country = value.country;
      this.exportFile.exportConsignee.termsOfPayment = value.termsOfPayment;
      this.exportFile.exportConsignee.hasBankingDetails = value.hasBankingDetails;
      this.exportFile.exportConsignee.commercialBankName = value.commercialBankName;
      this.exportFile.exportConsignee.commercialBankCode = value.commercialBankCode;
      this.exportFile.exportConsignee.commercialBankAccNo = value.commercialBankAccNo;
      this.exportFile.exportConsignee.commercialBankSwiftCode = value.commercialBankSwiftCode;
      this.exportFile.exportConsignee.defaultPurchaseTerm = value.purchaseTerm;
    }
  }

  openSundryChargeModel() {
    this.displaySundryModal = true;
  }

  closeSundryChargesModal() {
    this.displaySundryModal = false;
    this.createSundryCharges(this.exportFile.transportDocument);
  }

  private createSundryCharges(transportDocument: ExportTransportDocument) {
    const sundryCharges = transportDocument.valueBasedCharges.concat(transportDocument.rateBasedCharges);
    this.sundryCharges = sundryCharges.map(charge => {
      return {code: charge.sundryCharge.code, amount: charge.amount};
    });
  }

  selectLocalAgent(value) {
    this.exportFile.localAgent.code = value.code;
    this.exportFile.localAgent.name = value.name;
    this.exportFile.localAgent.label = value.label;
    this.exportFile.localAgent.address = value.address;
    this.exportFile.localAgent.customsRegCode = value.defaultCustomsCode;
  }

  updateWaybillPool(hawbNo: string) {
    this.airWaybillService.findByAirlineCode('ZZ').subscribe(
      (airWaybills: AirWaybill[]) => {
        if (airWaybills && airWaybills.length > 0) {
          const airWaybill = airWaybills[0];
          if (airWaybill.waybillNumbers) {
            airWaybill.waybillNumbers.forEach(el => {
              if (this.isConsolidation) {
                el.waybillUsages = el.waybillUsages.filter(u => u.fileNumber);
              } else {
                el.waybillUsages = el.waybillUsages.filter(u => u.fileNumber !== this.exportFile.clearingFileNumber);
              }
            });

            const waybillNumber = airWaybill.waybillNumbers.find(el => el.number === hawbNo);
            if (waybillNumber) {
              const usage = waybillNumber.waybillUsages.find(waybillUsage => waybillUsage.fileNumber === this.exportFile.clearingFileNumber);
              if (!usage) {
                waybillNumber.waybillUsages.push({fileNumber: this.exportFile.clearingFileNumber});
              }
            }
          }

          this.airWaybillService.save(airWaybill).subscribe(
            data => {
              this.notificationService.successNotify('Air Waybill Pool Updated Successfully');
            }
          );
        }
      }
    );
  }

  searchWaybillNumber() {
    if (this.filteredWaybillNumbers && this.filteredWaybillNumbers.length) {
      this.filteredWaybillNumbers = [...this.filteredWaybillNumbers];
    } else {
      this.filteredWaybillNumbers = [];
      if (this.exportFile && this.exportFile.transportDocument && this.exportFile.transportDocument.airline && this.exportFile.transportDocument.airline.code) {
        this.airWaybillService.findByAirlineCode(this.exportFile.transportDocument.airline.code).subscribe(
          (airWaybills: AirWaybill[]) => {
            this.filteredWaybillNumbers = this.airWaybillService.filterWaybillNumbers(airWaybills[0], this.exportFile.exporter.code, this.exportFile.clearingFileNumber);
          }
        );
      }
    }
  }

  onRowDelete(index) {
    this.exportFile.consolidationTransportDocuments.splice(index, 1);
    this.exportFile.consolidationTransportDocuments = [...this.exportFile.consolidationTransportDocuments];
    this.recreateDefaultTransportDocument();
  }

  private recreateDefaultTransportDocument() {
    if (this.exportFile.consolidationTransportDocuments.length === 0) {
      this.addNewConsolidationHawb(false);
    } else {
      this.selectedConsolidationHouseBill = this.exportFile.consolidationTransportDocuments[0];
    }
  }

  addNewConsolidationHawb(loadNextHawbNo) {
    if (loadNextHawbNo) {
      if (this.filteredWaybillNumbers && this.filteredWaybillNumbers.length) {
            this.filteredWaybillNumbers = [...this.filteredWaybillNumbers];
            this.createNewHawb();
          } else {
            this.filteredWaybillNumbers = [];
            if (this.exportFile && this.exportFile.transportDocument && this.exportFile.transportDocument.airline && this.exportFile.transportDocument.airline.code) {
              this.airWaybillService.findByAirlineCode(this.exportFile.transportDocument.airline.code).subscribe(
                (airWaybills: AirWaybill[]) => {
                  this.filteredWaybillNumbers = this.airWaybillService.filterWaybillNumbers(airWaybills[0], this.exportFile.exporter.code, this.exportFile.clearingFileNumber);
                  this.createNewHawb();
                }
              );
            }
          }
    } else {
        this.createNewHawb();
    }

    if (this.exportFile.consolidationTransportDocuments) {
      this.exportFile.consolidationTransportDocuments.push(this.selectedConsolidationHouseBill);
    } else {
      this.exportFile.consolidationTransportDocuments = [this.selectedConsolidationHouseBill];
    }
    this.onNewHouseBillAdded.emit();
  }

  private createNewHawb() {
    this.selectedConsolidationHouseBill = new ExportTransportDocument();
    this.selectedConsolidationHouseBill.exportConsignee = this.exportFile.exportConsignee;
    this.selectedConsolidationHouseBill.reservationDate = this.exportFile.transportDocument.reservationDate;
    this.selectedConsolidationHouseBill.packageDetails = this.exportFile.transportDocument.packageDetails;
    this.selectedConsolidationHouseBill.hawbNo = this.filteredWaybillNumbers[this.exportFile.consolidationTransportDocuments ? this.exportFile.consolidationTransportDocuments.length : 0];
    this.selectedConsolidationHouseBill.freight = this.exportFile.transportDocument.freight;
    this.selectedConsolidationHouseBill.measures = new Measures();
    this.selectedConsolidationHouseBill.departurePort = this.exportFile.transportDocument.departurePort;
    this.selectedConsolidationHouseBill.destinationPort = this.exportFile.transportDocument.destinationPort;
    this.selectedConsolidationHouseBill.handlingInformation = this.exportFile.transportDocument.handlingInformation;
    this.selectedConsolidationHouseBill.accountingInformation = this.exportFile.transportDocument.accountingInformation;
    this.updateWaybillPool(this.selectedConsolidationHouseBill.hawbNo);
  }
}
