/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */
import {Address, File, TransportDocument} from './file';
import {Uom} from './TariffCode';
import {CustomsTraderType} from '../../digi-business-entity/models/business-entity';

export class
ClearingFile extends File {
  importer?: Importer;
  reportFiles?: ReportFile[];
  paymentFiles?: PaymentFile[];
  clearingInstructionFiles?: ClearingInstructionFile[];
  transportDocuments?: TransportDocument[];
  transportDocument?: TransportDocument;
}

export class Importer {
  code?: string;
  address?: Address;
  name?: string;
  customsCode?: string;
  taxRegNo?: string;
  costingRequired?: boolean;
  costingUom?: Uom;
  registrationType?: CustomsTraderType;
  idNumber?: string;
  passportNumber?: string;
  reportsDefaultEmail?: string;

  [k: string]: any;
}

export class ReportFile {
  title?: string;
  deliveryAddress?: Address;
  attention?: string;
  importerPhoneNo: string;
  importerFaxNo: string;
  driver?: string;
  vehicleRegNo?: string;
}

export class PaymentFile {
  title?: string;
  clientName?: string;
  clientCode?: string;
  clientReference?: string;
  importer?: boolean;
  exporter?: boolean;
  other?: boolean;
  section2AmountRands?: string;
  section2AmountCents?: string;
  section2AmountWords?: string;
  branch?: string;
  reasonsForApplication?: string;
  section4SigningParty?: string;
  section4BusinessParty?: string;
  section4Capacity?: string;
  section4Location?: string;
  section4Date?: string;
  section5Mrn?: string;
  section5Date?: string;
  section5Supplier?: string;
  section5OfCountry?: string;
  section5MarksAndNumbers?: string;
  section5Coo?: string;
  section5TariffSubheading?: string;
  section5DescriptionOfGoods?: string;
  section5Value?: string;
  section5DutyRands?: string;
  section5DutyCents?: string;
  section5VatRands?: string;
  section5VatCents?: string;
  section6SigningParty?: string;
  section6BusinessParty?: string;
  section6Capacity?: string;
  section6Location?: string;
  section6Date?: string;
}

export class ClearingInstructionFile {
  title?: string;
  importerName?: string;
  telNumber?: string;
  importerAddress?: string;
  contactPerson?: string;
  importerCode?: string;
  vesselOrAirlineName?: string;
  importerVatNo?: string;
  bolOrAwb?: string;
  callSignOrFlightNo?: string;
  containerNo?: string;
  noOfPackages?: number;
  negotiableBolOrAwb?: boolean;
  formDa59?: boolean;
  destinationCode?: string;
  copyBolOrAwb?: boolean;
  packingList?: boolean;
  arrivalNotification?: boolean;
  bankPop?: boolean;
  commercialInvoices?: boolean;
  descrLiterature?: boolean;
  placeOfClearance?: string;
  enclosureOtherSpecify?: string;
  dutyPaid?: boolean;
  sch3ItemFlag?: boolean;
  dutyPaidEb?: boolean;
  industrialAndUseFlag?: boolean;
  warehouseFlag?: boolean;
  sch4ItemFlag?: boolean;
  ritFlag?: boolean;
  warehouseForExportFlag?: boolean;
  valuationCodeR?: boolean;
  valuationCodeN?: boolean;
  valuationCodeE?: boolean;
  valuationMethod1?: boolean;
  valuationMethod2?: boolean;
  valuationMethod3?: boolean;
  valuationMethod4?: boolean;
  valuationMethod5?: boolean;
  valuationMethod6?: boolean;
  invoiceNo?: string;
  permitNo?: string;
  tariffHeading?: string;
  rooNumber?: string;
  signingParty?: string;
  businessParty?: string;
  capacity?: string;
  initials?: string;
  surname?: string;
  date?: string;
}

export enum CustomsPurposeNames {
  DP = 'Duty Paid',
  DF = 'Duty Free (Section 38)',
  GR = 'General Rebate',
  IR = 'Industrial Rebate',
  RIB = 'Removal in Bond',
  RIT = 'Removal in Transit',
  IM = 'Import for Home Use',
  WH = 'Warehouse',
  WE = 'Warehousing for re-exportation',
}
