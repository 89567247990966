import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ExportFile} from '../../models/exportFile';
import {Airline, DepotTerminal, LocalAgent, OverseasAgent} from '../../models/file';
import {AirWaybill} from '../../models/AirWaybill';
import {TransportService} from '../../../digi-air/services/transport.service';
import {AirWaybillService} from '../../services/air-waybill.service';
import {DepotTerminalService} from '../../services/depot-terminal.service';
import {NotificationService} from '../../services/notification.service';
import {ShipmentType} from '../../models/enumerations';
import {Observable} from 'rxjs';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ValidationService} from '../../services/validation.service';

@Component({
  selector: 'digi-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
})
export class BookingComponent implements OnChanges {
  @Input() exportFile: ExportFile;
  filteredAirlines: Airline[];
  filteredWaybillNumbers: string[];
  callPurposes: string[];
  filteredDepotTerminals: DepotTerminal[];
  overseasAgents: Observable<any>;

  constructor(
    private transportService: TransportService,
    private airWaybillService: AirWaybillService,
    private depotTerminalService: DepotTerminalService,
    private notificationService: NotificationService,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    public validationService: ValidationService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.exportFile && changes.exportFile.currentValue) {
      this.exportFile = changes.exportFile.currentValue;
      if (!this.exportFile.transportDocument.shipmentType) {
        this.exportFile.transportDocument.shipmentType = 'STRAIGHT';
      }
    }

    this.overseasAgents = this.exportFileBehaviourSubject.getOverseasAgents();
  }

  searchAirline(event) {
    this.transportService.findAllAirLineStartsWith(event.query).subscribe(data => {
      this.filteredAirlines = data;
    });
  }

  selectAirline(value: Airline) {
    this.exportFile.transportDocument.airline.prefix = value.airlinePrefix;
    this.airWaybillService.findByAirlineCode(value.code).subscribe(
      (airWaybills: AirWaybill[]) => {
        this.filteredWaybillNumbers = this.airWaybillService.filterWaybillNumbers(airWaybills[0], this.exportFile.exporter.code, this.exportFile.clearingFileNumber);
        if (this.filteredWaybillNumbers.length > 0) {
          this.exportFile.transportDocument.mawbNo = this.filteredWaybillNumbers[0];
          this.updateWaybillPool(this.exportFile.transportDocument.mawbNo);
        }
      }
    );
  }

  searchWaybillNumber() {
    if (this.filteredWaybillNumbers && this.filteredWaybillNumbers.length) {
      this.filteredWaybillNumbers = [...this.filteredWaybillNumbers];
    } else {
      this.filteredWaybillNumbers = [];
      if (this.exportFile && this.exportFile.transportDocument && this.exportFile.transportDocument.airline && this.exportFile.transportDocument.airline.code) {
        this.airWaybillService.findByAirlineCode(this.exportFile.transportDocument.airline.code).subscribe(
          (airWaybills: AirWaybill[]) => {
            this.filteredWaybillNumbers = this.airWaybillService.filterWaybillNumbers(airWaybills[0], this.exportFile.exporter.code, this.exportFile.clearingFileNumber);
          }
        );
      }
    }
  }

  searchDepotTerminal(event, depotTerminalIndicator) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, depotTerminalIndicator).subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  updateCustomsOffice(value) {
    this.exportFile.customOffice = value;
  }

  selectLocalAgent(value) {
    this.exportFile.localAgent.code = value.code;
    this.exportFile.localAgent.name = value.name;
    this.exportFile.localAgent.label = value.label;
    this.exportFile.localAgent.address = value.address;
    this.exportFile.localAgent.customsRegCode = value.defaultCustomsCode;
    this.exportFile.localAgent.iataCode = value.iataCode;
  }

  selectCoLoader(value) {
    if (!this.exportFile.transportDocument.coLoader) {
      this.exportFile.transportDocument.coLoader = new LocalAgent();
    }
    this.exportFile.transportDocument.coLoader.code = value.code;
    this.exportFile.transportDocument.coLoader.name = value.name;
    this.exportFile.transportDocument.coLoader.label = value.label;
    this.exportFile.transportDocument.coLoader.address = value.address;
  }

  defaultReservationDate() {
    if (!this.exportFile.transportDocument.reservationDate) {
      this.exportFile.transportDocument.reservationDate = this.exportFile.transportDocument.departureDate;
    }
  }

  updateWaybillPool(mawbNo: string) {
    this.airWaybillService.findByAirlineCode(this.exportFile.transportDocument.airline.code).subscribe(
      (airWaybills: AirWaybill[]) => {
        if (airWaybills && airWaybills.length > 0) {
          const airWaybill = airWaybills[0];
          if (airWaybill.waybillNumbers) {
            airWaybill.waybillNumbers.forEach(el => {
              el.waybillUsages = el.waybillUsages.filter(u => u.fileNumber !== this.exportFile.clearingFileNumber);
            });

            const waybillNumber = airWaybill.waybillNumbers.find(el => el.number === mawbNo);
            const usage = waybillNumber.waybillUsages.find(waybillUsage => waybillUsage.fileNumber === this.exportFile.clearingFileNumber);
            if (!usage) {
              waybillNumber.waybillUsages.push({fileNumber: this.exportFile.clearingFileNumber});
            }
          }

          this.airWaybillService.save(airWaybill).subscribe(
            data => {
              this.notificationService.successNotify('Air Waybill Pool Updated Successfully');
            }
          );
        }
      }
    );
  }

  onShipmentType(shipmentType: ShipmentType) {
    this.exportFile.transportDocument.shipmentType = shipmentType;
    this.exportFile.transportDocument.straight = shipmentType === 'STRAIGHT';
  }

  isStraightBooking() {
    return this.exportFile.transportDocument.shipmentType === 'STRAIGHT';
  }

  selectOverseasAgent(value) {
    if (!this.exportFile.transportDocument.overseasAgent) {
      this.exportFile.transportDocument.overseasAgent = new OverseasAgent();
    }
    this.exportFile.transportDocument.overseasAgent.code = value.code;
    this.exportFile.transportDocument.overseasAgent.name = value.name;
    this.exportFile.transportDocument.overseasAgent.label = value.label;
    this.exportFile.transportDocument.overseasAgent.address = value.address;
  }

  defaultFinalDestinationPort(value) {
    this.exportFile.transportDocument.finalDestinationPort = Object.assign({}, value);
  }
}
