import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BillOfEntryMarksAndNumbers, ExportBillOfEntry} from '../../../digi-common/models/billOfEntries';
import {ExportsBillOfEntryService} from '../../../digi-common/services/exports-bill-of-entry.service';
import {RoadManifest, ManifestWaybill, PackType} from '../../../digi-common/models/roadManifest';
import {ExportConsignee, Exporter} from '../../../digi-common/models/exportFile';
import {Address, PortDetail} from '../../../digi-common/models/file';
import {ManifestService} from '../../../digi-common/services/manifest.service';
import {PackTypeService} from '../../../digi-common/services/pack-type.service';
import {Subscription} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {Branch} from '../../../digi-common/models/company';
import {CompanyService} from '../../../digi-common/services/company.service';

@Component({
  selector: 'digi-waybills',
  templateUrl: './waybills.component.html',
  styleUrls: ['./waybills.component.scss']
})
export class WaybillsComponent implements OnInit, OnChanges, OnDestroy {
  filteredBoes: ExportBillOfEntry[];
  cargoStatusCodes: SelectItem[];
  cols: any[];
  @Input() roadManifest: RoadManifest;
  @Output() onUpdateRoadManifest = new EventEmitter();
  selectedWaybill: ManifestWaybill = new ManifestWaybill();
  filteredPackTypes: PackType[];

  boeServiceSubscription: Subscription;
  packTypeServiceSubscription: Subscription;

  constructor(
    private boeService: ExportsBillOfEntryService,
    private manifestService: ManifestService,
    private companyService: CompanyService,
    private packTypeService: PackTypeService
  ) {
    this.cargoStatusCodes = [
      {label: '', value: null},
      {label: '5 - Part Shipment', value: '5'},
      {label: '9 - Full Shipment', value: '9'},
      {label: '10 - Final Part of Part Shipment', value: '10'}
    ];
    this.getBranchAndSetPackageType();
  }

  ngOnInit() {
    this.cols = [
      {field: 'lrn', header: 'LRN', width: '45%'},
      {field: 'hawbNo', header: 'Waybill No.', width: '45%'},
      {field: '', header: '', width: '10%'}
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roadManifest.currentValue) {
      this.roadManifest = changes.roadManifest.currentValue;
      if (this.roadManifest.waybills.length > 0) {
        this.selectedWaybill = this.roadManifest.waybills[0];
      } else {
        this.createNewWaybill();
      }
      this.getBranchAndSetPackageType();
      if (!this.selectedWaybill.mawbDate) {
        this.selectedWaybill.mawbDate = this.roadManifest.masterTransportDocDate;
      }

      if (!this.selectedWaybill.mawbNo) {
        this.selectedWaybill.mawbNo = this.roadManifest.manifestNo;
      }
    }
  }

  ngOnDestroy() {
    if (this.boeServiceSubscription) {
      this.boeServiceSubscription.unsubscribe();
    }

    if (this.packTypeServiceSubscription) {
      this.packTypeServiceSubscription.unsubscribe();
    }
  }

  searchBoE(event: any) {
    this.boeServiceSubscription = this.boeService.findByHawbNoOrMawbNo(event.query).subscribe(
      data => this.filteredBoes = data
    );
  }

  addBoeToRoadManifest(boe: ExportBillOfEntry) {
    if (this.roadManifest.waybills.length === 0) {
      this.roadManifest.manifestNo = boe.mawbNumber;
    }
    const foundEntry = this.roadManifest.waybills.find(el => el.lrn === boe.lrn && boe.transportDocNo === el.hawbNo);
    if (!foundEntry) {
      this.manifestService.createWaybill(this.roadManifest, boe);
    }
  }

  selectWaybill(event) {
    this.selectedWaybill = event.data;
    if (!this.selectedWaybill.marksAndNumbers) {
      const marksAndNumbers = new BillOfEntryMarksAndNumbers();
      marksAndNumbers.marksAndNumbers = [];
      marksAndNumbers.endorsements = [];
      this.selectedWaybill.marksAndNumbers = [marksAndNumbers];
    }
  }

  deleteWaybill(index: any) {
    this.roadManifest.waybills = this.roadManifest.waybills.filter((val, i) => i !== index);
  }

  updateConsignor(value) {
    this.selectedWaybill.consignor = value;
    this.selectedWaybill.consignor.customsCode = value.defaultCustomsCode;
  }

  updateConsignee(value) {
    this.selectedWaybill.consignee = value;
    this.selectedWaybill.consignee.customsCode = value.defaultCustomsCode;
  }

  updateRoadManifest(value) {
    this.onUpdateRoadManifest.emit(value);
  }

  searchPackTypes(event: any) {
    this.packTypeServiceSubscription = this.packTypeService.findByCodeOrDescription(event.query).subscribe(
      packTypes => this.filteredPackTypes = packTypes
    );
  }

  selectPackType(value) {
    this.selectedWaybill.packageType = value;
  }

  selectDeparturePort(value: PortDetail) {
    this.selectedWaybill.departurePort = value;
  }

  selectDestinationPort(value: PortDetail) {
    this.selectedWaybill.destinationPort = value;
  }

  createNewWaybill() {
    this.selectedWaybill = new ManifestWaybill();
    this.selectedWaybill.consignee = new ExportConsignee();
    this.selectedWaybill.consignee.address = new Address();
    this.selectedWaybill.consignor = new Exporter();
    this.selectedWaybill.consignor.address = new Address();

    const marksAndNumbers = new BillOfEntryMarksAndNumbers();
    marksAndNumbers.marksAndNumbers = [];
    marksAndNumbers.endorsements = [];
    this.selectedWaybill.marksAndNumbers = [marksAndNumbers];
    this.getBranchAndSetPackageType();
    this.roadManifest.waybills.push(this.selectedWaybill);

    if (!this.selectedWaybill.mawbDate) {
      this.selectedWaybill.mawbDate = this.roadManifest.masterTransportDocDate;
    }

    if (!this.selectedWaybill.mawbNo) {
      this.selectedWaybill.mawbNo = this.roadManifest.manifestNo;
    }
  }

  updateMarksAndNumbers(value) {
    this.selectedWaybill.marksAndNumbers = value;
  }
  private getBranchAndSetPackageType() {
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = this.roadManifest.branch ? companies[0].branches
          .find(el => el.code === this.roadManifest.branch) : companies[0].branches[0];
        for (let i = 0; i < this.roadManifest.waybills.length; i++) {
          // tslint:disable-next-line:max-line-length
          if (this.roadManifest.waybills[i].packageType === null || this.roadManifest.waybills[i].packageType === '' || typeof this.roadManifest.waybills[i].packageType === 'undefined') {
            this.roadManifest.waybills[i].packageType = branch.packageType;
          }
        }
      }
    });
  }

}
